<template>
  <div class="grey lighten-3">

        <v-row justify="center">
            <v-dialog
            :value="toggleTimer"
            @input="closeModal"
            scrollable
            max-width="800px"
            >
            <v-card>
                <v-card-title><v-icon color="#009263">mdi-block-helper</v-icon><span style="color:#009263" class="ml-2">Limite Atingido</span></v-card-title>
                <v-divider></v-divider>
                <br/>
                <v-card-text>
                    <h3 style="color: rgba(0, 0, 0, 0.87);">
                        O limite diário de questões que consegue visualizar foi atingido.
                    </h3>
                    <h3 style="color: rgba(0, 0, 0, 0.87);">
                        Inicie sessão para continuar a utilizar a aplicação ou, caso não tenha conta, registe-se no local apropriado.
                    </h3>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text >
                    <h2 class="mt-4 mb-8" style="text-align:center; color: #009263">Tempo Restante</h2>
                    <VueCountdown :transform="transformSlotProps" @end="ended" :time="$store.getters.getRemainingTimeParam(new Date())" v-slot="{  hours, minutes, seconds }">
                        <span class="mb-4" style="margin: auto; display: table; text-align:center; color: #009263; font-weight:bold; font-size: xxx-large">
                            {{ hours }}<span style="color:rgba(0, 0, 0, 0.87)">:</span>{{ minutes }}<span style="color:rgba(0, 0, 0, 0.87)">:</span>{{ seconds }}
                        </span>
                    </VueCountdown>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>

                <v-btn
                    color=""
                    text
                    @click="closeModal"
                >
                    Fechar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
  components:{
    VueCountdown     
  },
  data(){
    return {

    }
  },
  props: {
    toggleTimer: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    ended(){
      this.$emit("finished")
    },
    closeModal(){
      this.$emit("close")
    }
  },

  computed:{
  },

  mounted() {
  },
  watch: {

  },
};
</script>

<style scoped>
</style>