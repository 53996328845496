<template>
  <v-app id="app" :key="viewKey">
    <AuthApp @login="toggleLogin=true" :key="viewKey" @refreshLogout="refreshLogout" />
    <Login :toggleLogin="toggleLogin" @close="toggleLogin = false"/>
  </v-app>
</template>

<script>
import AuthApp from "@/views/AuthApp.vue";
import Login from "@/components/Login.vue";

export default {
  name: "App",
  components: {  AuthApp, Login },

  async created() {
    const token = localStorage.getItem("token");

    if (token) {
      this.$store.dispatch("tryLogin");
    }
    else {
      this.$store.dispatch("replenishTimer");
    } 
  },
  data() {
    return {
      viewKey: 0,
      loading: true,
      toggleLogin: false

    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  methods: {
    refreshLogout: function() {
      this.viewKey++;
    },
    openModal(){
      this.toggleLogin = true
    },
  },
  watch: {
    '$store.getters.isAuthenticated': {
      deep: true,
      //handler(newVal) {
      handler() {
        this.toggleLogin = false
        this.viewKey++;
      }
    }
  }
};
</script>

<style></style>
