//module.exports.hostAPI = "http://localhost:3060/";
module.exports.hostAPI = "https://apiqr.hypatiamat.com/";
module.exports.hostOffice = "https://backoffice.hypatiamat.com";
//module.exports.hostOffice = "http://localhost:8081";
module.exports.storageHosts = [
  // em desenvolvimento local, retirar de comentário
  //{
  //  origin: /localhost:8080$/,
  //  allow: ["get", "set", "del", "getKeys", "clear"],
  //},
  { origin: /hypatiamat.com$/, allow: ["get", "set", "del", "clear"] },
];

//module.exports.hostImages = "http://localhost:3333/" // + '1.png (split antes para retirar bckqr)
//module.exports.hostImages = "https://www.hypatiamat.com/imagens/" + bcqkr/uploads/1.png
module.exports.hostImages = {'dev': 'http://localhost:3060/', 'prod': 'https://www.hypatiamat.com/imagens/'}
module.exports.hostImagesResol = {'dev': 'http://localhost:3060/', 'prod': 'https://www.hypatiamat.com/imagens/propresolucao/'}
module.exports.hostImagesSelector = {'dev': 'http://localhost:3060/uploads/', 'prod': 'https://apiqr.hypatiamat.com/uploads/'}
module.exports.currHostImages = 'prod'