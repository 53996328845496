<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2 style="color:#009263">Questões Mensais</h2>
          </v-card-title>

          <div v-if="isLoggedIn">
            <v-card-title class="mt-n4 justify-center green--text">
              <h3 > <v-icon>mdi-chevron-right</v-icon> {{getCurrentMesAno}} <v-icon>mdi-chevron-left</v-icon></h3>
            </v-card-title>
                       <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página contém o conjunto de questões organizadas por categorias que foram selecionadas para o mês atual.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. Existem atualmente 4 categorias dedicadas para os vários anos do Ensino Básico e cada uma delas é representada por um botão na secção abaixo: 
                    <br/>
                    <span class="ml-4">
                      2.1. <b>Nível 1</b> - 1º e 2º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      2.2. <b>Nível 2</b> - 3º e 4º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      2.3. <b>Nível 3</b> - 5º e 6º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      2.4. <b>Nível 4</b> - 7º, 8º e 9º anos
                      <br/>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Cada categoria pode ter uma número variável de questões, desde 0 até um número arbitrário.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. Ao clicar numa categoria, irá abrir também um navegador semelhante ao presente em <b style="color:#009263">Resolver Questões</b> em que é possível visualizar todas as questões associadas à categoria.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. As questões só estão disponíveis para resolução durante o mês em que foram colocadas.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    6. A resolução de cada questão só pode ser submetida <b>uma vez</b> pelo que só no dia <b>1 do mês seguinte</b> é que será possível ver as soluções no <b style="color:#009263">Histórico de Questões</b>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    7. Não é possível alterar nem remover uma resolução após a submissão.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
            <div v-if="loading">
                <center>
                <v-img
                    :src="require('@/assets/loading.gif')"
                    width="150px"
                    heigth="150px"
                >
                </v-img>
                </center>
            </div>
            <div v-else>
                  <center><h2 style="color:#009263" class="mt-n2 mb-4">Categorias</h2></center>
                  <v-btn-toggle
                    v-model="categoria"
                    mandatory
                    @change="changeCategoria"
                    class="d-flex align-content-start flex-wrap"
                    color="#009263"
                  >
                    <v-btn
                          large
                          class="px-2 flex-grow-1 rounded-lg ml-auto mr-auto" 
                          v-for="(item,idx) in categorias"
                          :key="idx"
                          >
                          {{item}}
                    </v-btn>
                  </v-btn-toggle>
                  <center><h2 style="color:#009263" class="mt-4 mb-4">Questões Selecionadas</h2></center>
                  <!-- Question Scroller -->  
                  <div v-if="hasQuestions">
                    <div v-if="canSolveCategoria">
                      <v-row>
                      <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
                        <ResolverMensal 
                        :key="reloadPergunta" 
                        :pergunta="getCurrentPergunta"
                        :month="getMonth"
                        :year="getYear"
                        :categoria="categoria"
                        :hasResol="hasResolucao"
                        :userType="userType"
                        />
                      </v-col>
                    </v-row>
                      <!-- Botoes Next Prev-->
                    <v-row> 
                        <v-col cols=6>
                          <v-tooltip v-if="isFirstPage" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                disabled
                                large
                                fab
                                color="#009263"
                                class="white--text"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon dark x-large>
                                  mdi-chevron-left
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Questão Anterior</span>
                          </v-tooltip>
                          <v-tooltip v-else top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                large
                                fab
                                color="#009263"
                                class="white--text"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                @click="decrementPages"
                              >
                                <v-icon dark x-large>
                                  mdi-chevron-left
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Questão Anterior</span>
                          </v-tooltip>
                        </v-col>
                        <v-col class="text-right" cols=6>
                          <v-tooltip v-if="isLastPage" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                large
                                fab
                                color="#009263"
                                class="white--text"
                                v-bind="attrs"
                                v-on="on"
                                disabled
                              >
                                <v-icon dark x-large>
                                  mdi-chevron-right
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Questão Seguinte</span>
                          </v-tooltip>
                          <v-tooltip v-else top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                large
                                fab
                                color="#009263"
                                class="white--text"
                                v-bind="attrs"
                                v-on="on"
                                @click="incrementPages"
                              >
                                <v-icon dark x-large>
                                  mdi-chevron-right
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Questão Seguinte</span>
                          </v-tooltip>
                        </v-col>
                    </v-row>
                    </div>
                    <center v-else>
                      <h3>De momento não tem acesso a esta categoria visto que está destinada apenas a alunos atualmente no {{getCategoriaString}} anos de escolaridade.</h3>
                    </center>
                  </div>
                  <center v-else>
                  <h3>De momento não foram selecionadas questões para esta categoria.</h3>
                </center>
            </div>
          </div>
          <div v-else>
            <NoAuth @login="$emit('login')"/>
          </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require("moment")
//const currHostImages = require("@/config/hosts").currHostImages;
//const hostImages = require("@/config/hosts").hostImagesSelector[currHostImages];
//const Swal = require("sweetalert2");
//import Preview from "../components/Preview.vue";
import ResolverMensal from "../components/Resolver_Mensal.vue";
import NoAuth from "@/components/NoAuth.vue";


export default {
  components:{
    ResolverMensal, NoAuth
  },
  props: {

  },
  data() {
    return {
      loading: true,
      show:false,

      date: '',//new Date().toISOString().substr(0, 7),
      menu: false,

      questoes_categorias: {},
      
      categorias: ["Nível 1", "Nível 2", "Nível 3", "Nível 4"], // 0, 1, 2, 3
      categoria: 0,

      currentPergunta: 0,
      reloadPergunta: 1,

      resolucoes: {},

      user: null, 
      userId: null,
      userType: null
    };
  },
  methods: {
    async getHistory(){
      const data = await axios.get(host + 'questoes_mensais/mes')
      this.questoes_categorias = data.data
      this.loading = false
    },
    
    async getResolucoes(){
      const data = await axios.get(host + 'questoes_mensais_resolucoes/mes')
      this.resolucoes = data.data
    },

    async getUser() {
      try {
        this.userId = this.$store.getters.getUserId;
        this.userType = this.$store.getters.getUserType;
        if (this.userType === "professor") {
          const utilizador = await axios.get(host + "professores/" + this.userId);
          this.user = utilizador.data;
        } else if (this.userType === "aluno") {
          const utilizador = await axios.get(host + "alunos/" + this.userId);
          this.user = utilizador.data;
        }
      } catch (err) {
        const error = new Error(err.message || "Failed to query user");
        throw error;
      }
    },

    changeCategoria(){
      this.currentPergunta = 0
      this.reloadPergunta++
    },

    incrementPages(){
      this.reloadPergunta++
      this.currentPergunta = Math.min(this.currentPergunta + 1, this.questoes_categorias[this.categoria].length - 1)
    },

    decrementPages(){
      this.reloadPergunta++
      this.currentPergunta = Math.max(this.currentPergunta - 1, 0)
    }

  },
  computed: {
    hasQuestions(){
      let arr = this.questoes_categorias[this.categoria]
      
      return arr && arr.length > 0
    },

    getCurrentPergunta(){
      return this.questoes_categorias[this.categoria][this.currentPergunta]
    },

    isFirstPage(){
      return this.currentPergunta == 0
    },

    isLastPage(){
      return this.currentPergunta == this.questoes_categorias[this.categoria].length - 1
    },

    getCurrentMesAno(){
      return moment(new Date()).locale('pt').format('MMMM, YYYY')
    },

    getMonth(){
      return new Date().getMonth() + 1
    },

    getYear(){
      return new Date().getFullYear()
    },

    hasResolucao(){
      let currp = this.questoes_categorias[this.categoria][this.currentPergunta]
      if(!this.resolucoes[this.categoria]){
        return { hasResol: false, resol: null }
      }
      let idx = this.resolucoes[this.categoria].map(item => item.codquestao).indexOf(currp.cod)
      let obj = {
          hasResol: idx !== -1 ,
          resol: idx !== -1 ? this.resolucoes[this.categoria][idx].resol : null
      }
      return obj
    },

    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },

    getCategoriaString(){
      switch(this.categoria){
        case 0:
          return "1º e 2º"
        case 1:
          return "3º e 4º"
        case 2:
          return "5º e 6º"
        case 3: 
          return "7º, 8º e 9º"
        default:
          return ""
      }

    },
    canSolveCategoria(){
      if(this.userType === 'aluno' && this.user){
        let ano = parseInt(this.user.turma[0])
        switch(this.categoria){
          case 0:
            return ano === 1 || ano === 2
          case 1:
            return ano === 3 || ano === 4
          case 2:
            return ano === 5 || ano === 6
          case 3:
            return ano === 7 || ano === 8 || ano === 9
        }
      }
      return true 
    }

  },
  mounted() {
    if(this.$store.getters.isAuthenticated){
      this.getUser()
      this.getHistory()
      this.getResolucoes()
    }
  }
};
</script>

<style>
.v-date-picker-table > table > tbody > tr > td{
  border-left: 0px;
  /* background-color: red; */
}
</style>
