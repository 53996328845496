<template>

          <v-container v-if="loading">
            <center>
              <v-img
                :src="require('@/assets/loading.gif')"
                width="150px"
                heigth="150px"
              >
              </v-img>
            </center>
          </v-container>
          <v-container v-else>
           <v-row>
            <v-col cols="12" sm="12" md="12" lg="8" xl="8">
              <h2 style="color:#009263" class="ml-8 text-left my-2">
                <span>Questão</span>
              </h2>
              <h4 style="color:#009263" class="ml-8 text-left my-2">
                <span>[{{pergunta.cod}}]</span>
              </h4>
              <h4 style="color:#009263" class="ml-8 text-left my-2">
                <span>Nível {{pergunta.niveldificuldade}}</span>
              </h4>
              <h4 style="color:#009263" class="ml-8 text-left my-2">
                <span></span>
              </h4>
              <div
                v-html="pergunta.questao"
                class="mt-5 ml-8 mr-2"
                style="font-size: large"
              >
              </div>
              <div class="mt-5 ml-8">
                <!-- Resposta Aberta -->
                <div v-if="pergunta.tipo === 1" class="mt-8 mr-2">
                  <v-text-field
                    id="resp_aberta"
                    color="#009263"
                    outlined
                    hide-details
                    solo 
                    disabled
                    class="mb-5"
                  >
                     <template slot="label">
                      <span
                        class="black--text"
                        v-html="opcaoSelected"
                      ></span>
                    </template>
                    <template slot="append"> 
                      <span style="color:black!important; font-weight: bold!important; font-size: large; z-index:1" v-html="pergunta.unidade"></span> 
                    </template>
                  </v-text-field>

                  <SimpleKeyboard
                    v-if="!correta"
                    @onChange="changeKeyboard"
                    :input="opcaoSelected"
                  />
                </div>
                <!-- VER COMMENTS ABAIXO, COMPLETAR -->

              <!-- Escolha Múltipla TIPO 2 -->
              <v-container v-else-if="pergunta.tipo === 2">
                <v-radio-group v-model="opcaoSelected" :disabled="correta">
                  <v-row align="center">
                    <v-col
                      v-for="(resp, index) in getRespostas"
                      :key="index"
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      style="display: flex; align-items: center; justify-content: center"  
                      align="center"
                      class="ml-n4"
                    >
                      <v-radio :disabled="verified" color="#009263" :value="resp">
                        <template slot="label">
                          <v-card :color="getRespostasColor(resp)" class="pa-2 trucksicons d-flex align-center flex-column rounded-lg">
                            <v-img
                                contain
                                :src="grabRespostaURL(resp)"
                              >
<!--                                 <v-img
                                  contain 
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Red_x.svg/1200px-Red_x.svg.png"
                                >
                                </v-img> -->
                              </v-img>
                          </v-card>
                        </template>
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-container>
              <!-- Escolha Multipla TIPO 0, 3, 4, 5, 6 / VF -->
                <v-container v-else-if="pergunta.tipo < 7">
                  <v-radio-group v-model="opcaoSelected" :disabled="correta">
                    <v-radio
                      :disabled="verified"
                      color="#009263"
                      v-for="(resp, index) in getRespostas"
                      :key="index"
                      :value="resp"
                    >
                      <template slot="label">
                          <v-text-field
                            :id="resp"
                            color="#009263"
                            outlined
                            hide-details
                            solo 
                            disabled
                          >
                             <template slot="label">
                              <span
                                class="black--text"
                                v-html="resp"
                              ></span>
                            </template>
                          </v-text-field>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-container>

                <!-- Pintar -->
                <v-container v-else-if="pergunta.tipo === 7">
                      <!-- Vertical Grid -->
                  <div
                    v-if="tipoGrid === 'v'"
                  >
                    <v-row>
                              <v-col cols="6">
                                <div style="margin-top:10px;">
                                  <grid-layout
                                    :layout.sync="grid.layoutEsq"
                                    :col-num="21"
                                    :row-height="18"
                                    :is-draggable="grid.draggable"
                                    :is-resizable="grid.resizable"
                                    :vertical-compact="true"
                                    :use-css-transforms="true"
                                  >
                                    <grid-item
                                      :style="corGridEsq(item.i)"
                                      v-for="item in grid.layoutEsq"
                                      :x="item.x"
                                      :y="item.y"
                                      :w="item.w"
                                      :h="item.h"
                                      :i="item.i"
                                      :key="item.i"
                                    >
                                      <button
                                        class="btnItem"
                                        :disabled="disableGridEsq"
                                        @click="clickGrid(item.i)"
                                      ></button>
                                    </grid-item>
                                  </grid-layout>
                                </div>
                              </v-col>
                              <v-divider
                                style="border: 5px solid black;"
                                class="mx-n6"
                                vertical
                              ></v-divider>
                              <v-col cols="6">
                                <div style="margin-top:10px;">
                                  <grid-layout
                                    :layout.sync="grid.layoutDir"
                                    :col-num="21"
                                    :row-height="18"
                                    :is-draggable="grid.draggable"
                                    :is-resizable="grid.resizable"
                                    :vertical-compact="true"
                                    :use-css-transforms="true"
                                  >
                                    <grid-item
                                      :style="corGridDir(item.i)"
                                      v-for="item in grid.layoutDir"
                                      :x="item.x"
                                      :y="item.y"
                                      :w="item.w"
                                      :h="item.h"
                                      :i="item.i"
                                      :key="item.i"
                                    >
                                      <button
                                        class="btnItem"
                                        :disabled="disableGridDir"
                                        @click="clickGrid(item.i)"
                                      ></button>
                                    </grid-item>
                                  </grid-layout>
                                </div>
                              </v-col>
                    </v-row>
                  </div>

                  <!-- Horizontal Grid -->
                  <div
                    v-else
                  >
                    <v-row no-gutters justify="center" align="center">
                              <v-col cols="8">
                                <div class="my-n3">
                                  <grid-layout
                                    :layout.sync="grid.layoutTop"
                                    :col-num="26"
                                    :row-height="17"
                                    :is-draggable="grid.draggable"
                                    :is-resizable="grid.resizable"
                                    :vertical-compact="true"
                                    :use-css-transforms="true"
                                  >
                                    <grid-item
                                      :style="corGridTop(item.i)"
                                      v-for="item in grid.layoutTop"
                                      :x="item.x"
                                      :y="item.y"
                                      :w="item.w"
                                      :h="item.h"
                                      :i="item.i"
                                      :key="item.i"
                                    >
                                      <button
                                        class="btnItem"
                                        :disabled="disableGridTop"
                                        @click="clickGrid(item.i)"
                                      ></button>
                                    </grid-item>
                                  </grid-layout>
                                </div>
                              </v-col>

                              <v-col cols="8">
                                <v-divider
                                  style="border: 5px solid black;"
                                ></v-divider>
                              </v-col>
                              <v-col cols="8">
                                <div class="my-n3">
                                  <grid-layout
                                    :layout.sync="grid.layoutBtm"
                                    :col-num="26"
                                    :row-height="17"
                                    :is-draggable="grid.draggable"
                                    :is-resizable="grid.resizable"
                                    :vertical-compact="true"
                                    :use-css-transforms="true"
                                  >
                                    <grid-item
                                      :style="corGridBtm(item.i)"
                                      v-for="item in grid.layoutBtm"
                                      :x="item.x"
                                      :y="item.y"
                                      :w="item.w"
                                      :h="item.h"
                                      :i="item.i"
                                      :key="item.i"
                                    >
                                      <button
                                        class="btnItem"
                                        :disabled="disableGridBtm"
                                        @click="clickGrid(item.i)"
                                      ></button>
                                    </grid-item>
                                  </grid-layout>
                                </div>
                              </v-col>
                    </v-row>
                  </div> 
                </v-container>

                <!-- Qualquer outra -->
                <v-container v-else>
                  <span class="mt-5 ml-8 mr-2">
                    <v-icon dense color="#009263">mdi-exclamation-thick</v-icon> Ocorreu um erro na pré-visualização: tipo de pergunta não suportado.
                  </span>
                </v-container>
              </div>
              <div v-if="userType === 'aluno'" align="center" class="pa-2 mt-5 mb-5">
                  <v-btn
                    v-if="!hasResol.hasResol"
                    x-large
                    @click="confirmResposta"
                    class="white--text"
                    color="#009263"
                    :disabled="pergunta.tipo===7 ? opcaoSelected.length === 0 : !opcaoSelected || opcaoSelected === ''"
                    align="center"
                  >
                    Submeter
                  </v-btn>
                <v-btn v-else
                    x-large
                    class="white--text"
                    color="#009263"
                    disabled
                    align="center"
                  ><v-icon>mdi-check</v-icon>
                    Submetida
                  </v-btn>
              </div>
              <div v-else align="center" class="pa-2 mt-5 mb-5">
                  <h3>Não é possível submeter uma resposta. As questões mensais estão apenas destinadas a alunos do 1º ao 9º anos de escolaridade.</h3>
              </div>

            </v-col>
            <v-col class="mt-n2" cols="12" sm="12" md="12" lg="4" xl="4">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" lg="6">
                    <div>
                      <v-btn
                        v-if="pergunta.tipo===1 && pergunta.auxiliar===10"
                        @click="transferidor = !transferidor"
                        dark
                        small
                        rounded
                        color="#009263"
                        >Transferidor</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-card class="mt-n1 ">
                <v-img max-height="500px" contain :src="imagem"></v-img>
                <Moveable
                  v-if="transferidor"
                  v-bind="moveable"
                  @drag="handleDrag"
                  @rotate="handleRotate"
                >
                  <v-img src="@/assets/transferidor.png"></v-img>
                </Moveable>
              </v-card>
              <v-container>
                
                <div class="mt-3 mb-3" v-if="pergunta.idexame !== ''" id="exame">
                  <span> <b> Exame: </b> {{ pergunta.idexame }}</span>
                </div>
                <div v-else class="mt-3 mb-3">

                </div>
              </v-container>
            </v-col>
          </v-row>
          </v-container>
          
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const Swal = require("sweetalert2");
import { GridLayout, GridItem } from "vue-grid-layout";
const hostImages = require("@/config/hosts").hostImages;
const hostImagesResol = require("@/config/hosts").hostImagesResol;
const currHostImages = require("@/config/hosts").currHostImages;
import Moveable from "vue-moveable";
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";


export default {
  components: {
    GridLayout,
    GridItem,
    Moveable,
    SimpleKeyboard,
  },
  data() {
    
    return {
      loading: true,
      show: false,
      em_colors: {},
      verified: false, 
      missCounter: 0,
      successful: 0, // 0 no click, 1 click true, 2 click false,
      correta: false,
      submittable: false,
      likeStatus: false,

      toggleFavoritos: false,

      opcaoSelected: '',
      transferidor: false,
      dialog: false,
      moveable: {
        draggable: true,
        throttleDrag: 1,
        resizable: false,
        throttleResize: 1,
        keepRatio: true,
        scalable: false,
        throttleScale: 0.01,
        rotatable: true,
        throttleRotate: 0.2,
        pinchable: false,
        origin: false,
      },
      resolCard: "900px",
      grid: {
        layoutTop: [
          { x: 0, y: 0, w: 5, h: 2, i: "5", static: true },
          { x: 4, y: 0, w: 5, h: 2, i: "10", static: true },
          { x: 8, y: 0, w: 5, h: 2, i: "15", static: true },
          { x: 12, y: 0, w: 5, h: 2, i: "20", static: true },
          { x: 16, y: 0, w: 5, h: 2, i: "25", static: true },
          { x: 20, y: 0, w: 5, h: 2, i: "30", static: true },
          { x: 0, y: 1.4, w: 5, h: 2, i: "4", static: true },
          { x: 4, y: 1.4, w: 5, h: 2, i: "9", static: true },
          { x: 8, y: 1.4, w: 5, h: 2, i: "14", static: true },
          { x: 12, y: 1.4, w: 5, h: 2, i: "19", static: true },
          { x: 16, y: 1.4, w: 5, h: 2, i: "24", static: true },
          { x: 20, y: 1.4, w: 5, h: 2, i: "29", static: true },
          { x: 0, y: 2.8, w: 5, h: 2, i: "3", static: true },
          { x: 4, y: 2.8, w: 5, h: 2, i: "8", static: true },
          { x: 8, y: 2.8, w: 5, h: 2, i: "13", static: true },
          { x: 12, y: 2.8, w: 5, h: 2, i: "18", static: true },
          { x: 16, y: 2.8, w: 5, h: 2, i: "23", static: true },
          { x: 20, y: 2.8, w: 5, h: 2, i: "28", static: true },
          { x: 0, y: 4.2, w: 5, h: 2, i: "2", static: true },
          { x: 4, y: 4.2, w: 5, h: 2, i: "7", static: true },
          { x: 8, y: 4.2, w: 5, h: 2, i: "12", static: true },
          { x: 12, y: 4.2, w: 5, h: 2, i: "17", static: true },
          { x: 16, y: 4.2, w: 5, h: 2, i: "22", static: true },
          { x: 20, y: 4.2, w: 5, h: 2, i: "27", static: true },
          { x: 0, y: 5.6, w: 5, h: 2, i: "1", static: true },
          { x: 4, y: 5.6, w: 5, h: 2, i: "6", static: true },
          { x: 8, y: 5.6, w: 5, h: 2, i: "11", static: true },
          { x: 12, y: 5.6, w: 5, h: 2, i: "16", static: true },
          { x: 16, y: 5.6, w: 5, h: 2, i: "21", static: true },
          { x: 20, y: 5.6, w: 5, h: 2, i: "26", static: true },
        ],
        layoutBtm: [
          { x: 0, y: 0, w: 5, h: 2, i: "1", static: true },
          { x: 4, y: 0, w: 5, h: 2, i: "6", static: true },
          { x: 8, y: 0, w: 5, h: 2, i: "11", static: true },
          { x: 12, y: 0, w: 5, h: 2, i: "16", static: true },
          { x: 16, y: 0, w: 5, h: 2, i: "21", static: true },
          { x: 20, y: 0, w: 5, h: 2, i: "26", static: true },
          { x: 0, y: 1.4, w: 5, h: 2, i: "2", static: true },
          { x: 4, y: 1.4, w: 5, h: 2, i: "7", static: true },
          { x: 8, y: 1.4, w: 5, h: 2, i: "12", static: true },
          { x: 12, y: 1.4, w: 5, h: 2, i: "17", static: true },
          { x: 16, y: 1.4, w: 5, h: 2, i: "22", static: true },
          { x: 20, y: 1.4, w: 5, h: 2, i: "27", static: true },
          { x: 0, y: 2.8, w: 5, h: 2, i: "3", static: true },
          { x: 4, y: 2.8, w: 5, h: 2, i: "8", static: true },
          { x: 8, y: 2.8, w: 5, h: 2, i: "13", static: true },
          { x: 12, y: 2.8, w: 5, h: 2, i: "18", static: true },
          { x: 16, y: 2.8, w: 5, h: 2, i: "23", static: true },
          { x: 20, y: 2.8, w: 5, h: 2, i: "28", static: true },
          { x: 0, y: 4.2, w: 5, h: 2, i: "4", static: true },
          { x: 4, y: 4.2, w: 5, h: 2, i: "9", static: true },
          { x: 8, y: 4.2, w: 5, h: 2, i: "14", static: true },
          { x: 12, y: 4.2, w: 5, h: 2, i: "19", static: true },
          { x: 16, y: 4.2, w: 5, h: 2, i: "24", static: true },
          { x: 20, y: 4.2, w: 5, h: 2, i: "29", static: true },
          { x: 0, y: 5.6, w: 5, h: 2, i: "5", static: true },
          { x: 4, y: 5.6, w: 5, h: 2, i: "10", static: true },
          { x: 8, y: 5.6, w: 5, h: 2, i: "15", static: true },
          { x: 12, y: 5.6, w: 5, h: 2, i: "20", static: true },
          { x: 16, y: 5.6, w: 5, h: 2, i: "25", static: true },
          { x: 20, y: 5.6, w: 5, h: 2, i: "30", static: true },
        ],
        layoutEsq: [
          { x: 0, y: 0, w: 5, h: 2, i: "5", static: true },
          { x: 4, y: 0, w: 5, h: 2, i: "4", static: true },
          { x: 8, y: 0, w: 5, h: 2, i: "3", static: true },
          { x: 12, y: 0, w: 5, h: 2, i: "2", static: true },
          { x: 16, y: 0, w: 5, h: 2, i: "1", static: true },
          { x: 0, y: 1.6, w: 5, h: 2, i: "10", static: true },
          { x: 4, y: 1.6, w: 5, h: 2, i: "9", static: true },
          { x: 8, y: 1.6, w: 5, h: 2, i: "8", static: true },
          { x: 12, y: 1.6, w: 5, h: 2, i: "7", static: true },
          { x: 16, y: 1.6, w: 5, h: 2, i: "6", static: true },
          { x: 0, y: 3.2, w: 5, h: 2, i: "15", static: true },
          { x: 4, y: 3.2, w: 5, h: 2, i: "14", static: true },
          { x: 8, y: 3.2, w: 5, h: 2, i: "13", static: true },
          { x: 12, y: 3.2, w: 5, h: 2, i: "12", static: true },
          { x: 16, y: 3.2, w: 5, h: 2, i: "11", static: true },
          { x: 0, y: 4.8, w: 5, h: 2, i: "20", static: true },
          { x: 4, y: 4.8, w: 5, h: 2, i: "19", static: true },
          { x: 8, y: 4.8, w: 5, h: 2, i: "18", static: true },
          { x: 12, y: 4.8, w: 5, h: 2, i: "17", static: true },
          { x: 16, y: 4.8, w: 5, h: 2, i: "16", static: true },
          { x: 0, y: 6.4, w: 5, h: 2, i: "25", static: true },
          { x: 4, y: 6.4, w: 5, h: 2, i: "24", static: true },
          { x: 8, y: 6.4, w: 5, h: 2, i: "23", static: true },
          { x: 12, y: 6.4, w: 5, h: 2, i: "22", static: true },
          { x: 16, y: 6.4, w: 5, h: 2, i: "21", static: true },
          { x: 0, y: 8, w: 5, h: 2, i: "30", static: true },
          { x: 4, y: 8, w: 5, h: 2, i: "29", static: true },
          { x: 8, y: 8, w: 5, h: 2, i: "28", static: true },
          { x: 12, y: 8, w: 5, h: 2, i: "27", static: true },
          { x: 16, y: 8, w: 5, h: 2, i: "26", static: true },
        ],
        layoutDir: [
          { x: 0, y: 0, w: 5, h: 2, i: "1", static: true },
          { x: 4, y: 0, w: 5, h: 2, i: "2", static: true },
          { x: 8, y: 0, w: 5, h: 2, i: "3", static: true },
          { x: 12, y: 0, w: 5, h: 2, i: "4", static: true },
          { x: 16, y: 0, w: 5, h: 2, i: "5", static: true },
          { x: 0, y: 1.6, w: 5, h: 2, i: "6", static: true },
          { x: 4, y: 1.6, w: 5, h: 2, i: "7", static: true },
          { x: 8, y: 1.6, w: 5, h: 2, i: "8", static: true },
          { x: 12, y: 1.6, w: 5, h: 2, i: "9", static: true },
          { x: 16, y: 1.6, w: 5, h: 2, i: "10", static: true },
          { x: 0, y: 3.2, w: 5, h: 2, i: "11", static: true },
          { x: 4, y: 3.2, w: 5, h: 2, i: "12", static: true },
          { x: 8, y: 3.2, w: 5, h: 2, i: "13", static: true },
          { x: 12, y: 3.2, w: 5, h: 2, i: "14", static: true },
          { x: 16, y: 3.2, w: 5, h: 2, i: "15", static: true },
          { x: 0, y: 4.8, w: 5, h: 2, i: "16", static: true },
          { x: 4, y: 4.8, w: 5, h: 2, i: "17", static: true },
          { x: 8, y: 4.8, w: 5, h: 2, i: "18", static: true },
          { x: 12, y: 4.8, w: 5, h: 2, i: "19", static: true },
          { x: 16, y: 4.8, w: 5, h: 2, i: "20", static: true },
          { x: 0, y: 6.4, w: 5, h: 2, i: "21", static: true },
          { x: 4, y: 6.4, w: 5, h: 2, i: "22", static: true },
          { x: 8, y: 6.4, w: 5, h: 2, i: "23", static: true },
          { x: 12, y: 6.4, w: 5, h: 2, i: "24", static: true },
          { x: 16, y: 6.4, w: 5, h: 2, i: "25", static: true },
          { x: 0, y: 8, w: 5, h: 2, i: "26", static: true },
          { x: 4, y: 8, w: 5, h: 2, i: "27", static: true },
          { x: 8, y: 8, w: 5, h: 2, i: "28", static: true },
          { x: 12, y: 8, w: 5, h: 2, i: "29", static: true },
          { x: 16, y: 8, w: 5, h: 2, i: "30", static: true },
        ],
        draggable: false,
        resizable: false,
      },
      
    };
  },
  props: {
    pergunta:{
      type: Object,
      required: true
    },
    
    year: {
        type: Number,
        required: true
    },

    month: {
        type: Number,
        required: true
    },

    categoria: {
        type: Number,
        required: true
    },

    hasResol: {
        type: Object,
        required: true
    },

    userType: {
        type: String,
        required: true
    }

  },
  methods: {
    async getQuestao() {
      //const data = await axios.get(host + 'perguntas/' + this.$route.params.cod)
      //this.pergunta = data.data
      //console.log(this.pergunta)
      if(this.pergunta.tipo==2){
        for(let i = 1; i < 5; i++){
          this.em_colors[ this.pergunta[`resposta${i}`] ] = 'white'
        }
      }
      this.loading = false
    },

    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    getRespostasColor(resp){
      return this.em_colors[resp];
    },

    verify(){
      // if image, atualizar this.em_colors
      let resp, layout, splitted
      switch(this.pergunta.tipo){
        case 1: // WIP
          switch (this.pergunta.auxiliar) {
            case 2:
              if(this.opcaoSelected === `${this.pergunta.resposta1}/${this.pergunta.resposta2}`){
                this.correta = true
              }
              break;
            //transferidor
            case 10:
              if(this.opcaoSelected >= this.pergunta.resposta1 && this.opcaoSelected <= this.pergunta.resposta2){
                this.correta = true
              }
              break;
            case 22:
              splitted = this.opcaoSelected.split("/")
              if(this.reduce(splitted[0], splitted[1]) === this.reduce(this.pergunta.resposta1, this.pergunta.resposta2)){
                this.correta = true
              }
              break;
            case 1000:
              if (this.pergunta.resposta1 === this.opcaoSelected || this.pergunta.resposta2 === this.opcaoSelected){
                this.correta = true
              }
              break;
            // auxiliar 0 e 1 (...)
            default:
              for (let i = 1; i < 7; i++) {
                if (this.pergunta[`resposta${i}`] !== "" && this.pergunta[`resposta${i}`] === this.opcaoSelected) {
                  this.correta = true
                  break
                }
              }
              break
          }
          break
        case 2:
          if(this.opcaoSelected === this.pergunta.resposta1){
            this.correta = true
          }
          break;
        case 7:
          layout = this.pergunta.resposta1.split("-").splice(2)
          resp = this.opcaoSelected.sort((a, b) => parseInt(a) - parseInt(b))
          // comparar arrays ordenados
          if(layout.toString() === resp.toString()){
            this.correta = true
          }
          resp = this.opcaoSelected.join("-")
          break
        default: // 0 3 4 5 6
          if(this.pergunta.resposta1 === this.opcaoSelected){
            this.correta = true
          }
          break
      }
    },
    likeToggled(){
      if(this.likeStatus){
        this.likeStatus = false 
        this.$emit("remove_like", this.pergunta.cod)
      }
      else {
        this.likeStatus = true 
        this.$emit("add_like", this.pergunta.cod)
      }
    },

    changeKeyboard(input){
      //const cod = this.pergunta.cod;
      this.opcaoSelected = input
    },

    // Grid Horizontal
    corGridTop(index) {
      if (this.opcaoSelected === '') {
        this.opcaoSelected = []
      }
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const lado = idxList[1];
      idxList = idxList.splice(2);
      if (lado === "c")
        return idxList.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      else if(this.successful == 1){
        return this.opcaoSelected.includes(index)
          ? { background: "#4caf50" }
          : { background: "#eee" };
      }
      else if(this.successful == 2){
        return this.opcaoSelected.includes(index)
          ? { background: "rgba(255, 30, 30, 1)" }
          : { background: "#eee" };
      }
      else {
        return this.opcaoSelected.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      }
    },
    corGridBtm(index) {
      if (this.opcaoSelected === '') {
        this.opcaoSelected = []
      }
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const lado = idxList[1];
      idxList = idxList.splice(2);
      if (lado === "b")
        return idxList.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      else if(this.successful == 1){
        return this.opcaoSelected.includes(index)
          ? { background: "#4caf50" }
          : { background: "#eee" };
      }
      else if(this.successful == 2){
        return this.opcaoSelected.includes(index)
          ? { background: "rgba(255, 30, 30, 1)" }
          : { background: "#eee" };
      }
      else {
        return this.opcaoSelected.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      }
    },
    // Grid Vertical
    corGridEsq(index) {
      if (this.opcaoSelected === '') {
        this.opcaoSelected = []
      }
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const lado = idxList[1];
      idxList = idxList.splice(2);
      if (lado === "e")
        return idxList.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      else if(this.successful == 1){
        return this.opcaoSelected.includes(index)
          ? { background: "#4caf50" }
          : { background: "#eee" };
      }
      else if(this.successful == 2){
        return this.opcaoSelected.includes(index)
          ? { background: "rgba(255, 30, 30, 1)" }
          : { background: "#eee" };
      }
      else {
        return this.opcaoSelected.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      }
    },
    corGridDir(index) {
      if (this.opcaoSelected === '') {
        this.opcaoSelected = []
      }
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const lado = idxList[1];
      idxList = idxList.splice(2);
      if (lado === "d")
        return idxList.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      else if(this.successful == 1){
        return this.opcaoSelected.includes(index)
          ? { background: "#4caf50" }
          : { background: "#eee" };
      }
      else if(this.successful == 2){
        return this.opcaoSelected.includes(index)
          ? { background: "rgba(255, 30, 30, 1)" }
          : { background: "#eee" };
      }
      else {
        return this.opcaoSelected.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      }
    },

    clickGrid(item) {
      if(this.successful > 0){
        this.successful = 0
        this.correta = false
      }
      let resp = this.opcaoSelected
      if (resp.includes(item)) {
        const index = resp.indexOf(item);
        resp.splice(index, 1);
        this.opcaoSelected = resp;
      } else {
        resp.push(item);
        this.opcaoSelected = resp
      }
    },

    reduce(numerator, denominator) {
        let a = numerator
        let b = denominator
        let c
        while (b) {
            c = a % b; a = b; b = c
        }
        return `${numerator / a}/${denominator / a}`
    },

    autoFill(){
      if(this.pergunta.tipo===7){
          this.opcaoSelected = this.pergunta.resposta1.split("-").splice(2)
          this.successful = 1
      }
      else if (this.pergunta.tipo===1){
        if(!this.correta){
          switch (this.pergunta.auxiliar) {
            case 2:
              this.opcaoSelected = `${this.pergunta.resposta1}/${this.pergunta.resposta2}`
              break;
            case 10:
              this.opcaoSelected = this.pergunta.resposta1
              break;
            case 22:
              this.opcaoSelected = this.reduce(this.pergunta.resposta1,this.pergunta.resposta2)
              break;
            case 1000:
              this.opcaoSelected = this.pergunta.resposta1
              break;
            // auxiliar 0 e 1 (...)
            default:
              for (let i = 1; i < 7; i++) {
                if (this.pergunta[`resposta${i}`] !== "") {
                  this.opcaoSelected = this.pergunta[`resposta${i}`]
                  break
                }
              }
              break
            }
            let elements = document.querySelector(".v-text-field--outlined > .v-input__control > .v-input__slot")
            elements.querySelector("fieldset").style["border-color"] = "#4caf50"
            elements.querySelector("fieldset").style["background-color"] = "#4caf50"
        }
      }
      else {
        return
      }
      this.missCounter = 0
      this.correta = true
      this.submittable = true
    },

    handleDrag({ target, transform }) {
      target.style.transform = transform;
    },
    handleRotate({ target, transform }) {
      target.style.transform = transform;
    },
    imgSize(url, callback) {
      var img = new Image();
      img.onload = function() {
        callback(img.width);
      };
      img.src = url;
    },
    grabRespostaURL(obj){
      let img = obj
      if(obj && obj.match(/^bckqr\/uploads\/.+$/)){
        if(currHostImages === 'dev'){
          img = hostImages['dev'] + img.replace(/^bckqr\//, '') + '.png'
        }
        else {
          img = hostImages['prod'] + img + '.png'
        }
      }
      else {
        //let img = "addsub/img_add_sub_1"
        img = img
          ? `https://www.hypatiamat.com/imagens/${img.replace(".swf", "")}.png`
          : "";
        }
      return img;
    },

    openFavoritos(){
      this.toggleFavoritos = true
    },

    confirmResposta(){
        let resoll
        let cod = this.$store.getters.getUserId;

        if(this.pergunta.tipo === 7){
            let layout = this.pergunta.resposta1.substring(0,4)
            resoll = layout + this.opcaoSelected.sort((a, b) => parseInt(a) - parseInt(b)).join("-")
        }
        else {
            resoll = this.opcaoSelected
        }

        this.verify()

        let json = {
            year: this.year,
            month: this.month,
            categoria: this.categoria,
            codquestao: this.pergunta.cod,
            resol: resoll,
            resolvido_por: cod,
            resultado: this.correta ? 1 : 0
        }

        try {
        Swal.fire({
          title: `Após a submissão, a resposta não pode ser alterada. Tem a certeza que pretende continuar?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a operação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.post(host + 'questoes_mensais_resolucoes', json)
              .then(() => {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Resposta submetida com sucesso! No dia 1 do próximo mês pode visualizar a resolução.",
                  confirmButtonColor: "#009263",
                })
                .then(result => {
                  if(result.isConfirmed) {
                    this.$router.go(0)
                  }
                })
                .catch(e => {throw new Error(e)}) 
              })
              .catch(error => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: error.response.data.message,
                  confirmButtonColor: "#009263",
                })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível aceitar a submissão.");
        throw error;
      }

        //console.log("JSON", json)
    }
  },
  computed: {
    tipoGrid() {
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const tipoGrid = idxList[0];
      // h ou v
      return tipoGrid;
    },

    getRespostas(){
      let i, respostas = []
      switch(this.pergunta.tipo){
        case 0:
          i = 4
          break 
        case 2:
          i = 4
          break
        case 3:
          i = 2
          break
        case 4:
          i = 3
          break 
        case 5:
          i = 5
          break 
        case 6:
          i = 6
          break
      }
      for(let j = 1; j <= i; j++){
        respostas.push(this.pergunta[`resposta${j}`])
      }

      return this.shuffle(respostas);
    },

    getRespostasAberta(){
      let i = 1, respostas = []
      switch(this.pergunta.auxiliar){
            case 0:
              while( this.pergunta[`resposta${i}`] !== '' ) i++
              i--
              break
            case 1:
              i = 1
              break 
            default:
              i = 2
              break
      }
      for(let j = 1; j <= i; j++){
        respostas.push(this.pergunta[`resposta${j}`])
      }
      return respostas
    }, 

    imagem() {
      let img = this.pergunta.figura;

      if(img && img.match(/^bckqr\/uploads\/.+$/)){
        if(currHostImages === 'dev'){
          img = hostImages['dev'] + img.replace(/^bckqr\//, '') + '.png'
        }
        else {
          img = hostImages['prod'] + img + '.png'
        }
      }
      else {
        img = img
          ? `https://www.hypatiamat.com/imagens/${img.replace(".swf", "")}.png`
          : "";
      }

      return img;
    },

    resolWidth() {
      let img
      img = this.pergunta.resolucao;
      if(img && img.match(/^bckqr_r\/uploads\/.+$/)){
       if(currHostImages === 'dev'){
         img = hostImagesResol['dev'] + img.replace(/^bckqr_r\//, '') + '.png'
       }
       else {
         img = hostImagesResol['prod'] + img + '.png'
       }
      }
      else {
        img = img
          ? `https://www.hypatiamat.com/imagens/propresolucao/${img.replace(".swf","")}.png`
          : "";
      }
      this.imgSize(img, (w) => {
        this.resolCard = `${w}px`;
      });
      return this.resolCard;
    },

    resolucao() {
      let img = this.pergunta.resolucao;
       if(img && img.match(/^bckqr_r\/uploads\/.+$/)){
        if(currHostImages === 'dev'){
          img = hostImagesResol['dev'] + img.replace(/^bckqr_r\//, '') + '.png'
        }
        else {
          img = hostImagesResol['prod'] + img + '.png'
        }
      }
      else {
        img = img
          ? `https://www.hypatiamat.com/imagens/propresolucao/${img.replace(".swf","")}.png`
          : "";
      }
      
      return img;
    },

    resolDisabled(){
      if((this.pergunta.resolucao === '' || this.pergunta.resolucao === null) && this.pergunta.tipo !==7 ) return true
      else{
        switch(this.pergunta.tipo){
          case 1:
            return !this.correta && this.missCounter < 3
          case 2:
            return !this.verified
          case 7:
            return this.missCounter < 3 || this.correta
          default:
            return !this.verified
        }
      }
    },

    verificarDisabled(){
      switch(this.pergunta.tipo){
        case 1:
          return this.correta
        case 7:
          return this.correta
        default: 
          return this.opcaoSelected == '' || this.verified
      }
    },

    disableGridTop() {
      if (this.opcaoSelected === '') return "";
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const lado = idxList[1];
      return lado === "c" || this.correta;
    },

    disableGridBtm() {
      if (this.opcaoSelected === '') return "";
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const lado = idxList[1];
      return lado === "b" || this.correta;
    },

    disableGridEsq() {
      if (this.opcaoSelected === '') return "";
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const lado = idxList[1];
      return lado === "e" || this.correta;
    },

    disableGridDir() {
      if (this.opcaoSelected === '') return "";
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const lado = idxList[1];
      return lado === "d" || this.correta;
    },

  },
  mounted() {
    //this.getTemas()
    if(this.hasResol.hasResol){
        if(this.pergunta.tipo === 7){
            this.opcaoSelected = this.hasResol.resol.split("-").splice(2)
        }
        else {
            this.opcaoSelected = this.hasResol.resol
        }
        this.correta = true
    }
    this.getQuestao()
  },
};
</script>
<style scoped>

.vue-grid-item:not(.vue-grid-placeholder) {
  border: 1px solid black;
}

.selectExame {
  font-size: 14px;
  padding: 5px 6px;
  margin-top: -16px;
}
.selectNivel {
  color: #009263;
}

.btnItem {
  width: 100%;
  height: 100%;
  display: block;
}

.input {
  background-color: white;
  border: 2px solid #009263;
  box-shadow: 1px 1px 1px 0 lightgray inset;
  padding: 2px 8px;
  height: 55px;
  line-height: 45px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: auto;
}

.unidade {
  float: right;
}

</style>