<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center">
            <h2 style="color:#009263">Os Meus Favoritos</h2>
          </v-card-title>
                     <!-- Ajuda -->
        <div v-if="isLoggedIn">
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página mostra todas as listas de favoritos atualmente associadas à conta.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. Para adicionar questões às suas listas de favoritos, navegue para <b style="color:#009263">Resolver Questões</b> e selecione as que pretende incluir em cada uma.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Cada uma dessas listas é representada pela notação "<b style="color:#009263"> <v-icon color="#009263">mdi-folder-outline</v-icon> Lista</b>" e é possível visualizar o conteúdo de cada uma clicando em qualquer lugar desde esse título até ao botão <v-icon>mdi-chevron-down</v-icon> associado.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. Quando a lista está aberta, existe uma tabela que inclui todas as questões associadas a ela. É possível ordenar essa lista de forma <b>crescente</b>, <b>decrescente</b> ou <b>por defeito</b> relativamente a todas as colunas da tabela e esta ordem é controlada clicando nas setas <v-icon>mdi-arrow-up</v-icon> e <v-icon>mdi-arrow-down</v-icon>.                 
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. Cada tabela dispõe uma coluna denominada <b>Ações</b> que contém dois botões que desempenham funções distintas: 
                    <br/>
                      <span class="ml-4">
                        5.1. <v-btn small icon><v-icon color="#009263">mdi-launch</v-icon></v-btn>: abre a questão selecionada no visualizador presente em <b style="color:#009263">Resolver Questões</b>;
                        <br/>
                      </span>
                      <span class="ml-4">
                        5.2. <v-btn small icon><v-icon color="red">mdi-delete</v-icon></v-btn>: elimina a questão da lista de favoritos selecionada.
                        <br/>
                      </span>
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    6. As listas existirão sempre enquanto existirem questões nela, caso contrário serão removidas.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <!-- Tabela -->
          <v-container v-if="loading">
            <center>
              <v-img
                :src="require('@/assets/loading.gif')"
                width="150px"
                heigth="150px"
              >
              </v-img>
            </center>
          </v-container>
          <v-container v-else-if="!isEmpty">
            <v-list>
              <v-list-group
                v-for="[key, value] in Object.entries(favoritos)"
                :key="key"
                color="#009263" 
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <h2 style="color:#009263">
                      <v-icon color="#009263">mdi-folder-outline</v-icon>
                      {{key=="root" ? "Favoritos" : key}}
                    </h2>
                    <!-- <v-list-item-title v-text="key"></v-list-item-title> -->
                  </v-list-item-content>
                </template>

                <v-list-item>
                  <v-list-item-content>
                      <v-data-table
                        no-data-text="Não existem submissões deste tipo de momento."
                        :footer-props="{
                            'items-per-page-text': 'Mostrar',
                            'items-per-page-options': [10, 25, 50],
                            'items-per-page-all-text': 'Todos',
                        }"
                        dense
                        :items-per-page=25
                        :headers="headers" 
                        :items="value"
                        item-key="cod"
                        class="elevation-1"
                        color="#009263"
                        >
                        
                        <template v-slot:[`footer.page-text`]="props">
                        {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
                        </template>
                        <template v-slot:[`item.questao`]="{ item }">
                            <span v-html="item.questao"></span>
                        </template>
                        <template v-slot:[`item.tipo`]="{ item }">
                            {{ getTipo(item.tipo) }}
                        </template>
                        <template v-slot:[`item.tema`]="{ item }">
                            {{ getTema(item.tema) }}
                        </template>
                        <template v-slot:[`item.subtema`]="{ item }">
                            {{ getSubtema(item.tema, item.subtema) }}
                        </template>
                        <template v-slot:[`item.datacriacao`]="{ item }">
                            {{ formatDate(item.datacriacao) }}
                        </template>
                        <template v-slot:[`item.acoes`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="open(item)"
                              >
                                <v-icon color="#009263">
                                  mdi-launch
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Abrir</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="remove(key,item)"
                              >
                                <v-icon color="red">
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar</span>
                          </v-tooltip>

                        </template>
                      </v-data-table>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-container>
            <v-container align="center" justify="center" v-else>
                <v-img
                  :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
                <h2 style="text-align: center">De momento não tem nenhuma lista de favoritos.</h2>
                <v-row class="mt-2" align="center">
                  <v-col align="center"><v-btn @click="voltar" color="#009263" class="white--text">Ver Questões</v-btn></v-col>
                </v-row>
            </v-container>
        </div>
        <div v-else>
          <NoAuth @login="$emit('login')"/>
        </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require("moment")
const Swal = require("sweetalert2");
import NoAuth from "@/components/NoAuth.vue";

export default {
  components:{
    //Preview
    NoAuth
  },
  props: {

  },
  data() {
    return {
      loading: true,
      show:false,
      headers: [
        { text: "Código", value: "cod", class: "subtitle-1", width:'8%' },
        { text: "Tipo", value: "tipo", class: "subtitle-1", width:'13%'},
        { text: "Tema", value: "tema", class: "subtitle-1" ,width:'12%'},
        { text: "Subtema", value: "subtema", class: "subtitle-1" ,width:'18%'},
        { text: "Questão", value: "questao", class: "subtitle-1" ,width:'25%'},
        { text: "Data de Criação", value: "datacriacao", class: "subtitle-1", width:'13%'},
        { text: "Ações", value: "acoes", class: "subtitle-1"}
      ],

      temas: [],
      //values: {"root": null, "nova lista 1": null, "nova lista 2": null},
      favoritos:{},
      
    };
  },
  methods: {
    async getFavoritos(){
      const data = await axios.get(host + 'favoritos/utilizador')
      this.favoritos = data.data
      //console.log(this.favoritos)
      this.loading = false
    },

    async getTemas(){
      const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      this.temas = data.data
    },

    getTema(tema){
      return this.temas[tema] ? this.temas[tema].tema : ''
    },

    getSubtema(tema,subtema){
      return this.temas[tema] ? this.temas[tema].subtemas[subtema] : ''
    },

    getTipo(tipo){
      return tipo === 1 
      ? 'Resposta Aberta'
      : tipo === 3 
      ? 'Verdadeiro ou Falso'
      : tipo === 7 || tipo === 33
      ? 'Simetria'
      : 'Escolha Múltipla'
    },

    formatDate(date){
      return moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY, HH:mm:ss') : 'Indisponível'
    },

    remove(key, item){
      try {
        Swal.fire({
          title: `Tem a certeza que pretende eliminar a questão com código "${item.cod}" da lista "${key == 'root' ? 'Favoritos' : key}"?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a verificação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.delete(host + 'favoritos/'+ item.id)
              .then(() => {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Questão removida da lista selecionada com sucesso!",
                  confirmButtonColor: "#009263",
                })
                .then(result => {
                  if(result.isConfirmed) {
                    this.$router.go(0)
                  }
                })
                .catch(e => {throw new Error(e)}) 
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: 'Ocorreu um erro ao eliminar a questão dos favoritos.',
                  confirmButtonColor: "#009263",
                })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível eliminar a questão dos favoritos.");
        throw error;
      }
    },

    open(item){
      this.$router.push({
        name: "SubtemaSelector",
        params: {codtema: item.tema, scrollTo: {cod: item.cod, subtema: item.subtema}}
      })
    },

    voltar(){
      this.$router.push({
        name: "TemaSelector"
      })
    },

  },
  computed: {
    isEmpty(){
      return Object.keys(this.favoritos).length === 0
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },

  },
  async mounted() {
    if(this.$store.getters.isAuthenticated){
      this.getFavoritos()
      this.getTemas()
    }
  }
};
</script>

<style>
.swal2-textarea{
  border-color: #009263 !important
}
table th + th { border-left:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; }
</style>
