//const axios = require("axios");
//const host = require("@/config/hosts").hostAPI;
//const Swal = require("sweetalert2");
//import jwt_decode from "jwt-decode";
//
let timerQuestions;

export default {
  state() {
    return {
      counter: [],
      end: null,
      cap: 5
    };
  },
  mutations: {
    resetTimer(state){
      //console.log("RESET RECEIVED")
      state.counter = []
      state.end = null
      localStorage.removeItem('timerCounter')
      localStorage.removeItem('timerEnd')
      clearTimeout(timerQuestions)
    },
    incrementQuestionCounter(state, cod){
      let index =  state.counter.indexOf(cod);
      if (index === -1 && state.counter.length < state.cap){
        state.counter.push(cod)
        localStorage.setItem("timerCounter", JSON.stringify(state.counter));
      } 
      //state.counter = Math.min(state.counter + 1, state.cap)
    },
    startTimer(state){
      let now = Date.now()
      state.end = new Date(now + ( 3600 * 1000 * 24))
      localStorage.setItem("timerEnd", state.end);
    },
    setState(state, obj){
      state.counter = obj.counter 
      state.end = obj.end
    }
  },
  actions: {
    incrementQuestionCounter(context, cod){
      let i = context.getters.getQuestionCounter
      let j = context.getters.getCap
      if (i.length + 1 === j){
        context.commit("startTimer")
        timerQuestions = setTimeout(function() {
          context.commit("resetTimer");
        }, 86400000);
      }
      context.commit("incrementQuestionCounter", cod)
    },
    replenishTimer(context){
      if(context.getters.isCapped){
        let diff = context.getters.getRemainingTime
        //console.log("REPLENISH", diff)
        timerQuestions = setTimeout(function() {
          context.commit("resetTimer");
        }, diff);
      }
      let date = null
      if(localStorage.getItem('timerEnd')){
        date = new Date(localStorage.getItem('timerEnd'))
      }
      let obj = {
        counter: JSON.parse(localStorage.getItem('timerCounter')) || [],
        end: date
      }
      //console.log("OBJ", obj, typeof obj.end)
      context.commit('setState', obj)
    }
  },
  getters: {
    getTimerEnd(state) {
      //console.log(state.end)
      return state.end;
    },
    getQuestionCounter(state){
      return state.counter
    },
    getCap(state){
      return state.cap
    },
    isCapped(state){
      return state.counter.length === state.cap
    },
    getRemainingTime(state){
      // vue saves date as string?
      let curr = new Date()
      if(state.end){
        let res = new Date(state.end).getTime() - curr.getTime()
        return res
      }
      return 0
    },
    getRemainingTimeParam: (state) => (date) => {
      if(state.end){
        let res = new Date(state.end).getTime() - date.getTime()
        return res
      }
      return 0
    }
  },
};
