<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="returnTemas"
                color="#009263" fab dense large class="ml-n4 white--text mr-4"
                v-bind="attrs"
                v-on="on">
                <v-icon x-large>mdi-chevron-double-left</v-icon>
              </v-btn>
              </template>
              <span>Voltar ao Seletor de Temas</span>
            </v-tooltip>
            <h2 style="color:#009263">{{getTemaName}}</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página contém o conjunto de questões relativas a um dado tema.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. Todas as questões estão incluídas num subtema que é possível selecionar utilizando os botões abaixo que contêm o nome de cada um deles.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Após ser selecionado um subtema, aparecerá um navegador que inclui todas as questões do mesmo, onde é possível passar para a questão anterior e seguinte utilizando os botões <v-btn small fab color="#009263" class="white--text" dark><v-icon dark> mdi-chevron-left </v-icon></v-btn> e <v-btn small fab color="#009263" class="white--text" dark><v-icon dark> mdi-chevron-right </v-icon></v-btn>, respetivamente.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. É possível filtrar questões pelo <b>nível de dificuldade</b> associado utilizando os botões de seleção numerados de <b>1</b> a <b>5</b>. Se nenhum estiver selecionado (opção por defeito), aparecerão todas as questões do subtema atual.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. Para além da dificuldade, ao clicar no botão <v-btn icon color="#009263"><v-icon color="#009263">mdi-magnify</v-icon></v-btn> é possível filtrar questões relativamente a campos mais específicos (código, tipo, etc). Apenas aparecerão resultados relativamente ao subtema atual.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    6. Se estiver <b>autenticado</b>, é possível adicionar a questão às suas listas de favoritos utilizando o botão <v-btn small icon color="#009263"><v-icon color="#009263">mdi-star</v-icon></v-btn> assim como deixar um "Gosto" com o botão <v-btn small icon color="#009263"><v-icon color="#009263">mdi-thumb-up-outline</v-icon></v-btn>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    7. Os tipos de questões suportados atualmente são:
                    <br/>
                    <span class="ml-4">
                      7.1. Escolha Múltipla
                      <br/>
                    </span>
                    <span class="ml-4">
                      7.2. Verdadeiro ou Falso
                      <br/>
                    </span>
                    <span class="ml-4">
                      7.3. Resposta Aberta
                      <br/>
                    </span>
                    <span class="ml-4">
                      7.4. Simetria
                      <br/>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    8. Cada pergunta possui um botão <v-btn small color="#009263" class="white--text">VERIFICAR</v-btn> onde verifica se a solução que submeteu está correta e pode ter um botão <v-btn small color="#009263" class="white--text">Resolução</v-btn> que mostra a resolução do problema caso falhe o número de vezes suficientes.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    9. É possível voltar para o seletor de temas utilizando o botão <v-btn small fab color="#009263" class="white--text" dark><v-icon dark> mdi-chevron-double-left </v-icon></v-btn>.
                  </span>
                </v-col>
                  <v-col cols="12">
                  <span>
                    10. Pode resolver a mesma questão o número de vezes que pretender.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
          <v-container v-if="loading">
                    <center>
                      <v-img
                        :src="require('@/assets/loading.gif')"
                        width="150px"
                        heigth="150px"
                      >
                      </v-img>
                    </center>
          </v-container>
          <div v-else>
            <v-container align="center" justify="center" v-if="notfound">
                <v-img
                  :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
                <h2 style="text-align: center">A questão selecionada não existe ou está temporariamente indisponível.</h2>
                <v-row class="mt-2" align="center">
                  <v-col align="center"><v-btn @click="voltar" color="#009263" class="white--text">Voltar à Pagina Anterior</v-btn></v-col>
                </v-row>
            </v-container>
            <div v-else>
              <v-btn-toggle
                v-model="buttonToggle"
                mandatory
                @change="changedSubtema"
                class="d-flex align-content-start flex-wrap"
                color="#009263"
              >
             <v-btn
                    large
                    class="px-2 flex-grow-1 rounded-lg ml-auto mr-auto overflow-auto autowidth" 
                    v-for="(item,idx) in subtemas"
                    :key="idx"
                    >
                    {{item}}
              </v-btn>
              </v-btn-toggle>
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col align="center" justify="center" cols=12 class="mt-8">
                      <v-btn-toggle
                        id="btnGroup1"
                        v-model="dificuldadeToggle"
                        @change="dificuldadeToggled"
                        multiple
                        color="purple"
                      >
                        <v-tooltip v-for="i in 5" :key="i" top>
                          <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :id="`botao${i}`"
                            v-bind="attrs"
                            v-on="on">
                            <v-icon>{{i}}</v-icon>
                          </v-btn>
                          </template>
                          <span>Selecionar Nível de Dificuldade {{i}}</span>
                        </v-tooltip>
                      </v-btn-toggle>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-large
                          icon
                          color="#009263"
                          v-bind="attrs"
                          v-on="on"
                          @click="openFiltros"
                          >
                          <v-icon x-large color="#009263">mdi-magnify</v-icon>
                        </v-btn> 

                        </template>
                        <span>Pesquisar</span>
                      </v-tooltip>

<!--                       <v-btn-toggle
                        id="btnGroup2"
                        class="ml-4 mt-n4"
                        v-model="allToggle"
                        color="red"
                        @change="allToggled"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            style="border-radius:4px"
                            v-bind="attrs"
                            v-on="on">
                            <v-icon>T</v-icon>
                          </v-btn>
                          </template>
                          <span>Selecionar Todos os Níveis de Dificuldade</span>
                        </v-tooltip>
                      </v-btn-toggle> -->
                  </v-col>

                </v-row>
              <div v-if="!isEmpty">
                <Resolver 
                  :key="reloadPergunta" 
                  :pergunta="curr_pergunta" 
                  :gostosArr="gostos[curr_pergunta.cod]" 
                  :disableLikeButton="disableLikeButton"
                  :current_page="currentPage"
                  :max_pages="getTotalPages"
                  @remove_like="removeLike" 
                  @add_like="addLike" />
                <!-- Botoes Next Prev-->
                <v-row> 
                  <v-col cols=6>
                    <v-tooltip v-if="isFirstPage" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          disabled
                          large
                          fab
                          color="#009263"
                          class="white--text"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark x-large>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Questão Anterior</span>
                    </v-tooltip>
                    <v-tooltip v-else top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          large
                          fab
                          color="#009263"
                          class="white--text"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="previous"
                        >
                          <v-icon dark x-large>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Questão Anterior</span>
                    </v-tooltip>
                  </v-col>
                  <v-col class="text-right" cols=6>
                    <v-tooltip v-if="isLastPage" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          large
                          fab
                          color="#009263"
                          class="white--text"
                          v-bind="attrs"
                          v-on="on"
                          disabled
                        >
                          <v-icon dark x-large>
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Questão Seguinte</span>
                    </v-tooltip>
                    <v-tooltip v-else top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          large
                          fab
                          color="#009263"
                          class="white--text"
                          v-bind="attrs"
                          v-on="on"
                          @click="next"
                        >
                          <v-icon dark x-large>
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Questão Seguinte</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
              <v-container align="center" justify="center" v-else>
                <v-img
                  :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
                <h2 style="text-align: center">De momento não existem perguntas deste subtema nos filtros e dificuldade selecionados.</h2>
              </v-container>
            </div>
          </div>

        </v-card>
        <FilterList :dificuldades="dificuldadeToggle" :questoes="perguntas" :temas="temas" :toggleFiltros="toggleFiltros" @filtered="filtrarQuestoes" @close="toggleFiltros = false"/>
        <Timer v-if="!isLoggedIn" :toggleTimer="toggleTimer" @finished="finishedTimer" @close="toggleTimer = false"/>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
//const hostImages = require("@/config/hosts").hostImages;
//const hostImagesResol = require("@/config/hosts").hostImagesResol;
//const currHostImages = require("@/config/hosts").currHostImages;
import Resolver from "../components/Resolver.vue";
import FilterList from "@/components/FilterList.vue";
import Timer from "@/components/Timer.vue";


export default {
  components: {
    Resolver,
    FilterList,
    Timer
  },
  data() {
    return {
      loading: true,
      show: false,
      buttonToggle: undefined,
      codSubtema: null,
      currentPage: 0,
      reloadPergunta: 1,
      dificuldadeToggle: [],
      allToggle: 0,

      invalidTema: false, 
      invalidSubtema: false,
      
      temas: {},
      subtemas: null,
      perguntas: [],
      perguntasSliced: [],
      curr_pergunta: {},
      curr_subtema: 0,

      gostos: {},
      disableLikeButton: false,
      gotoquestion: false,
      notfound: false,

      toggleFiltros: false,
      toggleTimer: false
    };
  },
  props: {
    lastPage:{ // navigate back
      type: Number,
      default: 1
    },
    scrollTo:{
      type: Object,
      default: () => null
    }
  },
  methods: {
    async getTemas(){
      const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      this.temas = data.data
      this.subtemas = this.temas[this.$route.params.codtema].subtemas
      //console.log("TEMAS",this.temas)
      //console.log("SUBTEMAS",this.subtemas)
    },

    async getPerguntas(){
      this.loading = true
      if(!(this.buttonToggle==null || this.buttonToggle == undefined)){
        const data = await axios.get(host + `perguntas/visiveis/${this.$route.params.codtema}/${this.codSubtema}`)
        this.perguntas = data.data
        this.perguntasSliced = this.perguntas
        this.currentPage = 0
        if(this.gotoquestion){
          while(this.currentPage < this.perguntas.length){
            if(this.perguntas[this.currentPage].cod === this.scrollTo.cod){
              //console.log("FOUND IT", this.perguntas[this.currentPage])
              break
            }
            this.currentPage++ 
          }
          if(this.currentPage === this.perguntas.length){
            this.notfound = true
            //console.log("NOT FOUND")
          }
          this.gotoquestion = false
        }
        this.curr_pergunta = this.perguntas[this.currentPage]
        if(!this.$store.getters.isAuthenticated){
          if(this.$store.getters.isCapped){
            this.toggleTimer = true 
            //this.$store.commit("resetTimer")
            return
          }
          else if (this.perguntasSliced.length > 0){
            this.$store.dispatch("incrementQuestionCounter", this.curr_pergunta.cod)
            //this.$store.commit("resetTimer")
            //console.log(this.$store.getters.getQuestionCounter, this.$store.getters.getTimerStart, this.$store.getters.getTimerEnd, this.$store.getters.isCapped)
          }
        }
        //console.log("PERGUNTAS", this.perguntas)
        //this.scrollToQuestion()
      }
      this.loading = false
    },

    async getGostos(){
      const data = await axios.get(host + `perguntas/gostos/${this.$route.params.codtema}`)
      this.gostos = data.data
      //console.log("GOSTOS",this.gostos)
    },

    async scrollToQuestion(){
      console.log("scroll to question")
    },

    addLike(cod){
      this.disableLikeButton = true
      this.gostos[cod] = this.gostos[cod] || []
      this.gostos[cod].push(this.$store.getters.getUserId) // WIP LOGIN
      axios.post(host + 'gostos', { user: this.$store.getters.getUserId, questionid: cod })
        .then(() => {
          this.disableLikeButton = false
          //console.log("ADD_LIKE", this.gostos, this.gostos[this.curr_pergunta.cod])
        })
        .catch(e => {this.disableLikeButton = false; console.log(e)})
    },

    removeLike(cod){
      this.disableLikeButton = true
      this.gostos[cod] = this.gostos[cod] || []
      this.gostos[cod] = this.gostos[cod].filter(item => item != this.$store.getters.getUserId) // WIP LOGIN
      axios.delete(host + 'gostos/'+ cod)
        .then(() => {
          this.disableLikeButton = false
          //console.log("REM_LIKE", this.gostos, this.gostos[this.curr_pergunta.cod])
        })
        .catch(e => {this.disableLikeButton = false; console.log(e)})
    },

    allToggled(){
      //console.log("ALL TOGGLED", this.allToggle)
      //this.allToggle = 0
      this.perguntasSliced = this.perguntas
      this.currentPage = 0
      this.curr_pergunta = this.perguntasSliced[this.currentPage]
      this.reloadPergunta++
    },

    dificuldadeToggled(){
      if(this.dificuldadeToggle.length===0){
        this.allToggle = 0
      }
      else {
        this.allToggle = undefined
      }

      if(this.allToggle != 0){
        this.perguntasSliced = this.perguntas.filter( p => {
          return this.dificuldadeToggle.includes(p.niveldificuldade - 1)
        })
      }
      else {
        this.perguntasSliced = this.perguntas
      }

      this.currentPage = 0
      this.curr_pergunta = this.perguntasSliced[this.currentPage]
      this.reloadPergunta++
      //console.log("SLICING", this.perguntasSliced)
    },
    
    changedSubtema(){
      if(this.gotoquestion){
        this.codSubtema = this.scrollTo.subtema
        this.buttonToggle = Object.keys(this.subtemas).indexOf(this.codSubtema)
      }
      else {
        this.codSubtema = Object.keys(this.subtemas)[this.buttonToggle]
      }
      //console.log("SUBTEMA SELECTED ", this.codSubtema)
      this.getPerguntas()
    },

    previous(){
      if(!this.$store.getters.isAuthenticated){
        if(this.$store.getters.isCapped){
          this.toggleTimer = true 
          //this.$store.commit("resetTimer")
          return
        }
        else if (this.perguntasSliced[this.currentPage - 1]){
          this.$store.dispatch("incrementQuestionCounter", this.perguntasSliced[this.currentPage - 1].cod)
          //this.$store.commit("resetTimer")
          //console.log(this.$store.getters.getQuestionCounter, this.$store.getters.getTimerStart, this.$store.getters.getTimerEnd, this.$store.getters.isCapped)
        }
      }
      this.currentPage = Math.max(this.currentPage - 1, 0)
      this.curr_pergunta = this.perguntasSliced[this.currentPage]
      this.reloadPergunta++
    },

    next(){
      if(!this.$store.getters.isAuthenticated){
        if(this.$store.getters.isCapped){
          this.toggleTimer = true 
          //this.$store.commit("resetTimer")
          return
        }
        else if (this.perguntasSliced[this.currentPage + 1]){
          this.$store.dispatch("incrementQuestionCounter", this.perguntasSliced[this.currentPage + 1].cod)
          //this.$store.commit("resetTimer")
          //console.log(this.$store.getters.getQuestionCounter, this.$store.getters.getTimerStart, this.$store.getters.getTimerEnd, this.$store.getters.isCapped)
        }
      }
      this.currentPage = Math.min(this.currentPage + 1, this.perguntasSliced.length - 1)
      this.curr_pergunta = this.perguntasSliced[this.currentPage]
      this.reloadPergunta++
    },

    returnTemas(){
      let page_before = this.lastPage
      //console.log("PASSING PAGE", page_before)
      this.$router.push({
        name: "TemaSelector",
        params: {page_before}
      })
    },

    voltar(){
      this.$router.go(-1)
    },

    openFiltros(){
      this.toggleFiltros = true
    },

    filtrarQuestoes(filtered){
      this.perguntasSliced = filtered
      this.currentPage = 0
      this.curr_pergunta = this.perguntasSliced[this.currentPage]
      this.reloadPergunta++
      //console.log("FILTER AFTER", this.perguntasSliced)
    },

    finishedTimer(){
      this.$store.commit("resetTimer")
      this.$router.go()
    }
  },
  computed: {
    isEmpty(){
      return this.perguntasSliced.length===0
    },
    isFirstPage(){
      return this.currentPage === 0
    },
    isLastPage(){
      return this.currentPage === this.perguntasSliced.length - 1
    },
    getTemaName(){
      return this.temas[this.$route.params.codtema]?.tema
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    getTotalPages(){
      return this.perguntasSliced.length
    }
  },

  async mounted() {
    if(this.scrollTo != null){
        //console.log("YOU NEED TO SCROLL", this.scrollTo)
        this.gotoquestion = true
    }
    this.getTemas()
    this.getGostos()
    this.getPerguntas()
  },
  watch: {
    '$store.getters.isCapped': {
      deep: true,
      //handler(newVal) {
      handler(newVal, oldVal) {
        //console.log(oldVal,newVal)
        if(oldVal === false && newVal === true){
          this.toggleTimer = true
        }
        else if(oldVal === true && newVal === false){
          this.$router.go()
        }
      }
    }
  }
};
</script>
<style scoped>
.v-btn__content {
  white-space: normal;
  flex: auto;
}

#btnGroup1 .v-item--active > span > i {
  color:#9c27b0!important;
}

#btnGroup2 .v-item--active > span > i {
  color:#ff4747!important;
}

#btnGroup1 .v-item--active{
  border-width: thin;
  border-color:#9b27b07e!important
}

#btnGroup2 .v-item--active{
  border-width: thin;
  border-color:#ff47477e!important
}

/* #botao2, #botao3, #botao4, #botao5{
  border-left: 0px;
} */

.autowidth{
  max-width: 100%!important;
  /* width:100% !important; */
}

</style>