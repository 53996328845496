<template>
  <div class="grey lighten-3">

        <v-row justify="center">
            <v-dialog
            :value="toggleFavoritos"
            @input="closeModal"
            scrollable
            max-width="800px"
            >
            <v-card>
                <v-card-title><v-icon color="#009263">mdi-star</v-icon><span style="color:#009263">Adicionar aos Favoritos</span></v-card-title>
                <v-divider></v-divider>
                <br/>
                <v-card-text>
                    <h3 style="color: rgba(0, 0, 0, 0.87);">
                        Selecione das listas abaixo aquelas que pretende que contenham a questão.
                    </h3>
                    <h3 style="color: rgba(0, 0, 0, 0.87);">
                        Caso necessite de criar uma lista nova, insira o nome dela no campo abaixo e clique no botão <v-icon color="#009263">mdi-plus-box</v-icon>.
                    </h3>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text style="height:300px">
                    <v-list >
                        <v-list-item-group
                            v-model="model"
                            multiple
                        >
                            <template v-for="(item, i) in items">
                            <v-divider
                                v-if="!item"
                                :key="`divider-${i}`"
                            ></v-divider>

                                <v-list-item
                                    v-else
                                    :key="`item-${i}`"
                                    :value="item"
                                    color="#009263"
                                    active-class="text--accent-4"
                                >
                                    <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item"></v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-checkbox
                                        :input-value="active"
                                        color="#009263"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>

                    <v-row align="center">
                        <v-text-field
                            style="padding: 20px 25px 20px 15px"
                            align="center"
                            color="#009263"
                            label="Adicionar uma lista nova"
                            append-outer-icon="mdi-plus-box"
                            v-model="nomelista"
                            @click:append-outer="addLista"
                            ></v-text-field>
                    </v-row>


                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-tooltip v-if="model.length==0" top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on">
                            <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="#009263"
                            class="white--text"
                            disabled
                            >
                                Guardar
                            </v-btn>
                        </div>
                      </template>
                      <span>Selecione pelo menos uma lista</span>
                    </v-tooltip>
                    <v-btn v-else
                      color="#009263"
                      class="white--text"
                      :loading="submitting"
                      @click="save"
                      >
                        Guardar
                    </v-btn>
                <v-btn
                    color=""
                    text
                    @click="closeModal"
                >
                    Cancelar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        <v-snackbar
          v-model="snackbar"
          text
          color="#009263"
        >
          <h3>Favoritos atualizados com sucesso.</h3>

          <template v-slot:action="{ attrs }">
            <v-btn
              color="#009263"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Ok
            </v-btn>
          </template>
        </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;

export default {
  data(){
    return {
      submitting: false,
      favoritos: {},

      items: [],
      model: [],

      nomelista: '',
      snackbar: false
    }
  },
  props: {
    codquestao: {
      type: String,
      required: true
    },
    toggleFavoritos: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async getFavoritos(){
      const data = await axios.get(host + 'favoritos/utilizador')
      this.favoritos = data.data
      if(Object.keys(this.favoritos).length === 0){
        this.items.push('Favoritos')
      }
      else {
        this.items = Object.keys(this.favoritos).map( item => item === 'root' ? 'Favoritos' : item)
        for(let key of Object.keys(this.favoritos)){
          let perguntas = this.favoritos[key]
          perguntas.forEach(p => {
            if(p.cod === this.codquestao){
              this.model.push(key==='root' ? 'Favoritos' : key)
            }
          })
        }
      }
      //console.log(this.favoritos)
    },

    addLista(){
        if(this.nomelista == '') return
        let trimmed = this.nomelista.trim()
        if(!this.items.includes(trimmed)){
            this.items.push(trimmed)
        }
        if(!this.model.includes(trimmed)){
            this.model.push(trimmed)
        }
        this.nomelista = ''
        //console.log(this.model)
    },

    save(){
      this.submitting = true
      let folders = this.model.map(folder => folder === 'Favoritos' ? 'root' : folder)
      //console.log(folders)
      axios.post(host + 'favoritos/listas/' + this.codquestao, { folders })
        .then(dados => {
          console.log(dados)
          this.closeModal()
          this.snackbar = true
          this.submitting = false
        })
        .catch( e => {
          this.closeModal()
          this.submitting = false
          console.log(e)
        })
    },

    closeModal(){
      this.$emit("close")
    }
  },

  computed:{
  },

  mounted() {
      this.getFavoritos()
  },
  watch: {

  },
};
</script>

<style scoped>
</style>