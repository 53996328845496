import Vue from "vue";
import VueRouter from "vue-router";
import Favoritos from "@/views/Favoritos.vue";
import TemaSelector from "@/views/TemaSelector.vue";
import SubtemaSelector from "@/views/SubtemaSelector.vue";
import QuestoesHistory from "@/views/QuestoesHistory.vue";
import QuestoesMensais from "@/views/QuestoesMensais.vue";
//import RankingHistory from "@/views/RankingHistory.vue";
import Ranking from "@/views/Ranking.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/queroresolverquestoesde" },
  {
    path: "/historico",
    name: "QuestoesHistory",
    props: true,
    component: QuestoesHistory,
  },
  {
    path: "/favoritos",
    name: "Favoritos",
    props: true,
    component: Favoritos,
  },
  {
    path: "/queroresolverquestoesde",
    name: "TemaSelector",
    props: true,
    component: TemaSelector,
  },
  {
    path: "/questoesmensais",
    name: "QuestoesMensais",
    props: true,
    component: QuestoesMensais,
  },
  {
    path: "/classificacoes",
    name: "Ranking",
    props: true,
    component: Ranking,
  },
/*   {
    path: "/classificacoes/historico",
    name: "RankingHistory",
    props: true,
    component: RankingHistory,
  }, */
  {
    path: "/queroresolverquestoesde/tema/:codtema",
    name: "SubtemaSelector",
    props: true,
    component: SubtemaSelector
  },
  // NOT FOUND
  { path: "*", redirect: "/queroresolverquestoesde" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// caso token seja removido pelo backoffice
router.beforeEach((to, from, next) => {
  const auth = store.getters.isAuthenticated;
  const token = localStorage.getItem("token");
  if (!token && auth) store.dispatch("logout");
  else next();
});

export default router;
