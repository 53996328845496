<template>
  <div class="grey lighten-3">

        <v-row justify="center">
            <v-dialog
            :value="toggleFiltros"
            @input="closeModal"
            scrollable
            max-width="800px"
            >
            <v-card>
                <v-card-title><v-icon color="#009263">mdi-magnify</v-icon><span style="color:#009263">Pesquisar Questões</span></v-card-title>
                <v-divider></v-divider>
                <br/>
                <v-card-text>
                    <h3 style="color: rgba(0, 0, 0, 0.87);">
                        Utilize os filtros abaixo para encontrar mais facilmente a(s) questão(ões) que pretende.
                    </h3>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text style="height:350px">

                  <v-row align="center" class="mt-2">
                      <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                        <v-textarea
                          color="#009263"
                          rows="1"
                          auto-grow
                          hide-details
                          clearable
                          @input="codigo = $event !== null ? $event : ``"
                          outlined
                          type="text"
                          label="Código"
                          prepend-icon="mdi-code-tags"
                          v-model="codigo"
                          >
                        </v-textarea>
                      </v-col>
                      <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                            color="#009263"
                            outlined
                            hide-details
                            clearable
                            v-model="anoSel"
                            @input="anoSel = $event !== null ? $event : ``"
                            type="number"
                            label="Ano"
                            min="1"
                            max="9"
                            prepend-icon="mdi-ruler-square-compass"
                          ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row> 
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-select
                          color="#009263"
                          item-color="green"
                          outlined
                          clearable
                          flat
                          v-model="tipoSel"
                          @input="tipoSel = $event !== null ? $event : ``"
                          @change="changeTipo()"
                          :items="tipos"
                          label="Tipo"
                          hide-details
                          prepend-icon="mdi-clipboard-list-outline"
                        ></v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                            <v-select
                              color="#009263"
                              item-color="green"
                              outlined
                              flat
                              clearable
                              :disabled="tipoSel!=='Resposta Aberta'"
                              v-model="subtipoSel"
                              @input="subtipoSel = $event !== null ? $event : ``"
                              @change="changeSubtipo()"
                              :items="subtipos"
                              label="Subtipo"
                              hide-details
                              prepend-icon="mdi-flag-variant-outline"
                            ></v-select>
                    </v-col>
                  </v-row>
<!--                   <v-row>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-combobox
                        color="#009263"
                        item-color="green"
                        outlined
                        clearable
                        flat
                        v-model="temaSelected"
                        @click:clear="wipeSubtema"
                        :items="temasFiltro"
                        label="Tema"
                        hide-details
                        @change="changeSubtemas"
                        prepend-icon="mdi-format-list-bulleted"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-combobox
                        color="#009263"
                        item-color="green"
                        v-model="subtemaSelected"
                        outlined
                        clearable
                        hide-details
                        flat
                        :items="subtemasFiltro"
                        :disabled="!temaSelected"
                        label="Subtema"
                        prepend-icon="mdi-format-list-text">
                      ></v-combobox>
                    </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-combobox
                      color="#009263"
                      item-color="green"
                      hide-details
                      clearable
                      @input="exameValue = $event !== null ? $event : ``"
                      outlined
                      v-model="exameValue"
                      :items="exames"
                      flat
                      label="Exame"
                      prepend-icon="mdi-school-outline"
                    ></v-combobox>
                  </v-col>
                </v-row> 
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-textarea
                        color="#009263"
                        rows="1"
                        auto-grow
                        clearable
                        @input="questao = $event !== null ? $event : ``"
                        hide-details
                        outlined
                        type="text"
                        label="Questão"
                        prepend-icon="mdi-clipboard-edit-outline"
                        v-model="questao">
                      </v-textarea>
                    
                    </v-col>
                  </v-row>
<!--                   <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-text-field
                          color="#009263"
                          outlined
                          hide-details
                          clearable
                          v-model="respostaSel"
                          @input="respostaSel = $event !== null ? $event : ``"
                          label="Respostas 1 a 6"
                          prepend-icon="mdi-pencil"
                        ></v-text-field>
                  </v-col>
                </v-row>   -->

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn 
                      color="#009263"
                      class="white--text"
                      @click="filtrarQuestoes"
                      >
                        Pesquisar
                    </v-btn>
                <v-btn
                    color=""
                    text
                    @click="closeModal"
                >
                    Cancelar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;

export default {
  data(){
    return {
      submitting: false,
      favoritos: {},

      results: [], 
      exames: [],
      catalogoTemas: {},
      temasFiltro: [],
      subtemasFiltro: [],
      tipos: ['Escolha Múltipla', 'Resposta Aberta', 'Verdadeiro ou Falso', 'Simetria'],
      subtipos: ["1 Resposta Correta", "2 Respostas Corretas", "Várias Respostas Corretas", "Fração Redutível", "Fração Irredutível", "Utilizar Transferidor"],
      subtipoSel: '',
      exameValue: '',
      temaSelected: '',
      subtemaSelected: '',
      anoSel: '',
      nivelSel: '',
      respostaSel: '',
      questao: '',
      codigo: '',
      tipoSel: '',
      anoRules: [(v) => v && v > 0 && v < 10],
      nivelRules: [(v) => v && v > 0 && v < 5],
      auxiliar: 0
    }
  },
  props: {
    toggleFiltros: {
      type: Boolean,
      default: false
    },
    temas: {
      type: Object,
      required: true
    },
    questoes: {
      type: Array,
      required: true
    },
    dificuldades: {
      type: Array,
      required: true
    }
  },

  methods: {
    async getExames(){
      const data = await axios.get(host + 'perguntas/exames')
      this.exames.push('Nenhum')
      this.exames.push('Qualquer Um')
      this.exames.push( ...data.data )
    },

    closeModal(){
      this.$emit("close")
    },

    getTema(tema){
      return this.temas[tema]?.tema 
    },

    getSubtema(tema,subtema){
      return this.temas[tema]?.subtemas[subtema]
    },

    getTipo(tipo){
      return tipo === 1 
      ? 'Resposta Aberta'
      : tipo === 3 
      ? 'Verdadeiro ou Falso'
      : tipo === 7 || tipo === 33
      ? 'Simetria'
      : 'Escolha Múltipla'
    },

    changeTipo(){
      this.subtipoSel = '' 
    },

    changeSubtipo(){
      if (this.tipoSel !== this.tipos[1]){
        this.auxiliar = 0
      }
      else{
        switch(this.subtipoSel){
          case this.subtipos[0]:
            this.auxiliar = 1
            break
          case this.subtipos[1]:
            this.auxiliar = 1000
            break
          case this.subtipos[2]:
            this.auxiliar = 0
            break
          case this.subtipos[3]:
            this.auxiliar = 22
            break
          case this.subtipos[4]:
            this.auxiliar = 2
            break
          case this.subtipos[5]:
            this.auxiliar = 10
            break
          default:
            this.auxiliar = 0
            break
        }
      }
    },

    changeSubtemas(){
      this.subtemaSelected = ''
      let tema = this.catalogoTemas[this.temaSelected]
      if(this.temaSelected != null) this.subtemasFiltro = Object.entries(this.temas[tema].subtemas).map(e => e[1])
      else this.subtemasFiltro = []
    },

    wipeSubtema(){
      this.temaSelected = ''
      this.subtemaSelected = ''
    },

    getSubtemaNr(tema){
      let subtemas = this.temas[tema].subtemas 
      if(this.temaSelected != '' && this.subtemaSelected != ''&& this.temaSelected != null && this.subtemaSelected != null) {
          for (var [key, value] of Object.entries(subtemas)) {
              if(this.subtemaSelected == value) return key
          }
      }
      return ''
    },

    filtrarQuestoes(){
      let filtered = [...this.questoes]
              .filter(questao => {
                return questao.cod.toLowerCase().includes(this.codigo.toLowerCase()) &&
                  questao.questao.toLowerCase().includes(this.questao.toLowerCase()) &&
                  this.getTipo(questao.tipo).toLowerCase().includes(this.tipoSel.toLowerCase()) &&
                  (this.exameValue==='Nenhum' ?  questao.idexame.toLowerCase() === '' : this.exameValue==='Qualquer Um' ?  questao.idexame.toLowerCase() !== '' : questao.idexame.toLowerCase().includes(this.exameValue.toLowerCase())) && 
                  questao.ano.toString().toLowerCase().includes(this.anoSel.toString().toLowerCase()) &&
                  (this.dificuldades.length === 0 ? true : this.dificuldades.includes(questao.niveldificuldade - 1)) && 
                  (this.tipoSel === this.tipos[1] && this.subtipoSel !== '' && this.subtipoSel !== null ? questao.auxiliar === this.auxiliar : true)
              })
      this.$emit("filtered", filtered)
      this.closeModal()
    }
  },

  computed:{
  },

  mounted() {
    this.getExames()
  },
  watch: {
    $props: {
            handler() {
              this.temasFiltro = Object.entries(this.temas).map(entry => entry[1].tema)
              this.temasFiltro.sort((a, b) => a.localeCompare(b))
              this.catalogoTemas = Object.fromEntries(Object.entries(this.temas).map(entry => [entry[1].tema, entry[0]]))
              //console.log("FILTROS BEFORE", this.questoes)
            },
            deep: true,
            immediate: true,
        },
  },
};
</script>

<style scoped>
</style>