<template>
            <v-container align="center" justify="center">
                <v-img
                  :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
                <h2 style="text-align: center">Para ter acesso à página tem de iniciar sessão primeiro.</h2>
                <v-row class="mt-2" align="center">
                  <v-col align="center"><v-btn @click="$emit('login')" color="#009263" class="white--text">Iniciar Sessão</v-btn></v-col>
                </v-row>
            </v-container>
</template>

<script>

export default {
};
</script>
<style scoped>
</style>