var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grey lighten-3"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"value":_vm.toggleFavoritos,"scrollable":"","max-width":"800px"},on:{"input":_vm.closeModal}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"#009263"}},[_vm._v("mdi-star")]),_c('span',{staticStyle:{"color":"#009263"}},[_vm._v("Adicionar aos Favoritos")])],1),_c('v-divider'),_c('br'),_c('v-card-text',[_c('h3',{staticStyle:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" Selecione das listas abaixo aquelas que pretende que contenham a questão. ")]),_c('h3',{staticStyle:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" Caso necessite de criar uma lista nova, insira o nome dela no campo abaixo e clique no botão "),_c('v-icon',{attrs:{"color":"#009263"}},[_vm._v("mdi-plus-box")]),_vm._v(". ")],1)]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_vm._l((_vm.items),function(item,i){return [(!item)?_c('v-divider',{key:("divider-" + i)}):_c('v-list-item',{key:("item-" + i),attrs:{"value":item,"color":"#009263","active-class":"text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"#009263"}})],1)]}}],null,true)})]})],2)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-text-field',{staticStyle:{"padding":"20px 25px 20px 15px"},attrs:{"align":"center","color":"#009263","label":"Adicionar uma lista nova","append-outer-icon":"mdi-plus-box"},on:{"click:append-outer":_vm.addLista},model:{value:(_vm.nomelista),callback:function ($$v) {_vm.nomelista=$$v},expression:"nomelista"}})],1)],1),_c('v-divider'),_c('v-card-actions',[(_vm.model.length==0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"#009263","disabled":""}},'v-btn',attrs,false),on),[_vm._v(" Guardar ")])],1)]}}],null,false,1831245420)},[_c('span',[_vm._v("Selecione pelo menos uma lista")])]):_c('v-btn',{staticClass:"white--text",attrs:{"color":"#009263","loading":_vm.submitting},on:{"click":_vm.save}},[_vm._v(" Guardar ")]),_c('v-btn',{attrs:{"color":"","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"text":"","color":"#009263"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"#009263","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Ok ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h3',[_vm._v("Favoritos atualizados com sucesso.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }