<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2 style="color:#009263">Classificações</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página contém as tabelas de classificações, resultantes das questões mensais, para os vários anos letivos organizados por categorias.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. É possível navegar entre os anos letivos utilizando os botões <v-btn small color="#009263" class="white--text ml-2" rounded><v-icon>mdi-chevron-left</v-icon></v-btn> e<v-btn small color="#009263" class="white--text ml-2" rounded><v-icon>mdi-chevron-right</v-icon></v-btn>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Existem atualmente 4 categorias dedicadas para os vários anos do Ensino Básico e cada uma delas é representada por um botão na secção abaixo: 
                    <br/>
                    <span class="ml-4">
                      3.1. <b>Nível 1</b> - 1º e 2º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      3.2. <b>Nível 2</b> - 3º e 4º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      3.3. <b>Nível 3</b> - 5º e 6º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      3.4. <b>Nível 4</b> - 7º, 8º e 9º anos
                      <br/>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. É possível ver a classificação por ano letivo tendo em conta todas as categorias selecionando <b style="color:#009263">TODAS</b>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. Ao fim de selecionar uma das categorias, será mostrada a tabela de classificação respetiva.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    6. Cada uma das linhas da tabela representa um <b>utilizador</b> diferente e estão ordenadas pelo <b>número total</b> de questões que estes resolveram corretamente. 
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    7. As posições de 1º, 2º e 3º lugar darão direito a uma distinção especial sob a forma de um troféu:
                    <br/>
                    <span class="ml-4">
                      7.1. <b>1º lugar</b>: <v-icon color="#FFD700"> mdi-trophy</v-icon>
                      <br/>
                    </span>
                    <span class="ml-4">
                      7.2. <b>2º lugar</b>: <v-icon color="#C0C0C0"> mdi-trophy</v-icon>
                      <br/>
                    </span>
                    <span class="ml-4">
                      7.3. <b>3º lugar</b>: <v-icon color="#CD7F32"> mdi-trophy</v-icon>
                      <br/>
                    </span>
                  </span>
                </v-col>
<!--                 <v-col cols="12">
                  <span>
                    8. Caso pretenda visualizar mais detalhadamente as classificações, pode utilizar o botão <v-btn fab color="#009263" class="white--text" small><v-icon>mdi-history</v-icon></v-btn> na secção da <b style="color:#009263">Tabela</b>. 
                  </span>
                </v-col> -->
                <v-col cols="12">
                  <span>
                    8. As classificações são atualizadas no <b>dia 1 de cada mês</b>.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
            <div v-if="loading">
                <center>
                <v-img
                    :src="require('@/assets/loading.gif')"
                    width="150px"
                    heigth="150px"
                >
                </v-img>
                </center>
            </div>
            <div v-else>
              <div v-if="anos.length==0">
                <center>
                  <h3>De momento não existem classificações disponíveis.</h3>
                </center>
              </div>
              <div v-else>
                  <v-row align="center" justify="center">
                    <v-col align="center">
                      <v-btn
                        color="#009263"
                        class="white--text"
                        large
                        rounded
                        @click="backOne"
                        :disabled="prevDisabled"
                      >
                        <v-icon>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                      <span style="color:#009263; font-weight: bold; font-size: larger" class="ml-2 mr-2">{{getCurrentAno}}</span>
                      <v-btn
                        color="#009263"
                        class="white--text mr-2"
                        large
                        rounded
                        @click="forwardOne"
                        :disabled="nextDisabled"
                        >
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                    
                  </v-row>
                  <center><h2 style="color:#009263" class="mt-4 mb-4">Categorias</h2></center>
                  <v-btn-toggle
                    v-model="categoria"
                    mandatory
                    @change="changeCategoria"
                    class="d-flex align-content-start flex-wrap"
                    color="#009263"
                  >
                    <v-btn
                          large
                          class="px-2 flex-grow-1 rounded-lg ml-auto mr-auto" 
                          v-for="(item,idx) in categorias"
                          :key="idx"
                          >
                          {{item}}
                    </v-btn>
                  </v-btn-toggle>
                  <center><h2 style="color:#009263" class="mt-4 mb-4">Tabela</h2></center>
                  <!-- Ranking -->
                  <v-row align="center">
                    <v-col align="center" justify="center">
                      <!-- <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                large
                                fab
                                color="#009263"
                                class="white--text"
                                v-bind="attrs"
                                v-on="on"
                                @click="navigateHistorico"
                              >
                                <v-icon dark x-large>
                                  mdi-history
                                </v-icon>
                              </v-btn>
                            </template>
                      <span>Ver Classificações Mensais</span>
                    </v-tooltip> -->
                    </v-col>
                  </v-row>  

                    <v-list v-if="hasElements">
                      <div v-for="(rank,index) in getCurrentRanking"
                        :key="index">
                      <v-list-item

                      >
                        <v-list-item-icon v-if="index===0">
                          <v-icon color="#FFD700"> mdi-trophy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else-if="index===1">
                          <v-icon color="#C0C0C0"> mdi-trophy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else-if="index===2">
                          <v-icon color="#CD7F32"> mdi-trophy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else>
                          <v-icon></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title v-if="isSelf(rank.aluno)" style="color:#009263" class="font-weight-bold" v-text="index + 1 + 'º. ' + rank.aluno"></v-list-item-title>
                          <v-list-item-title v-else class="font-weight-bold" v-text="index + 1 + 'º. ' + rank.aluno"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content align="right">
                          <v-list-item-title class="font-weight-bold" v-text="rank.total"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                        <v-divider
                        ></v-divider>
                      </div>
                    </v-list>
                  <center v-else>
                  <h3>Não existem classificações disponíveis no ano letivo e categoria selecionados.</h3>
                </center>
              </div>
            </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;

export default {
  components:{
  },
  props: {

  },
  data() {
    return {
      loading: true,
      show:false,

      anos: [],
      anosLetivos: [],
      currentAno: 0,

      mesesString: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
      meses: [],
      currentMes: 0,
      
      categorias: ["Todas", "Nível 1", "Nível 2", "Nível 3", "Nível 4"], // 0, 1, 2, 3
      categoria: 0,

      classificacoes: {},
    };
  },
  methods: {
    async getHistory(){
      const data = await axios.get(host + 'questoes_mensais/disponiveis')
      this.history = data.data
      this.anos = Object.keys(this.history).reverse()
      if(this.anos.length > 0){
        this.meses = Object.keys(this.history[this.anos[this.currentAno]]).reverse()
      }
      this.loading = false
    },

    async getRanking(){
      const data = await axios.get(host + 'questoes_mensais_resolucoes/classificacoes/anosletivos')
      this.classificacoes = data.data
      this.anos = Object.keys(this.classificacoes).reverse()
      //console.log(this.classificacoes)
      this.loading = false
    },
    
    
    forwardOne(){
      this.currentMes = 0
      this.categoria = 0
      this.currentAno = Math.max(0 , this.currentAno - 1)
      this.meses = Object.keys(this.classificacoes[this.anos[this.currentAno]]).reverse()
    },

    backOne(){
      this.currentMes = 0
      this.categoria = 0
      this.currentAno = Math.min(this.currentAno + 1, this.anos.length - 1)
      this.meses = Object.keys(this.classificacoes[this.anos[this.currentAno]]).reverse()
    },

    forwardOneMes(){
      this.categoria = 0
      this.currentMes = Math.max(0 , this.currentMes - 1)
    },

    backOneMes(){
      this.categoria = 0
      this.currentMes = Math.min(this.currentMes + 1, this.meses.length - 1)
    },

    changeCategoria(){
    },

    navigateHistorico(){
      this.$router.push({
        name: "RankingHistory",
      })
    }

  },
  computed: {
    getCurrentAno(){
      return this.anos[this.currentAno]
    },

    getCurrentMes(){
      return this.mesesString[this.meses[this.currentMes] - 1]
    },

    prevDisabled(){
      return this.currentAno == this.anos.length - 1
    },

    nextDisabled(){
      return this.currentAno == 0
    },

    prevDisabledMes(){
      return this.currentMes == this.meses.length - 1
    },

    nextDisabledMes(){
      return this.currentMes == 0
    },

    hasElements(){
      if(this.categoria != 0){
        let arr = this.classificacoes[this.anos[this.currentAno]][this.categoria - 1]
        return arr && Object.keys(arr).length > 0
      }
      return true
    },

    getCurrentRanking(){
      // WIP
      let iterate
      if(this.categoria === 0){
        iterate = Object.entries(Object.entries(this.classificacoes).reduce( (acc, curr) => {
          acc[curr[0]] = acc[curr[0]] || {}
          Object.entries(curr[1]).forEach(js => {
            Object.entries(js[1]).forEach(j => {
                acc[curr[0]][j[0]] = acc[curr[0]][j[0]] || 0
                acc[curr[0]][j[0]] += j[1]
            })
          })
          return acc
        }, {})[this.anos[this.currentAno]])
        //console.log(iterate)
      }
      else {
        iterate = Object.entries(this.classificacoes[this.anos[this.currentAno]][this.categoria - 1])
      }
      return iterate.reduce( (acc, curr) => {
        acc.push({aluno: curr[0], total: curr[1]})
        return acc
      }, []).sort((a,b) => b.total - a.total)
    },

    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },

    isSelf(){
      return (name) => {
        if(this.$store.getters.isAuthenticated){
          let id = this.$store.getters.getUserId
          return id === name
        }
        return false
      }
    },
  },
  mounted() {
    this.getRanking()
  }
};
</script>

<style>
.v-date-picker-table > table > tbody > tr > td{
  border-left: 0px;
  /* background-color: red; */
}
</style>
