<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2 style="color:#009263">Histórico de Questões Mensais</h2>
          </v-card-title>

          <div v-if="isLoggedIn">
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página contém o conjunto de questões mensais de edições passadas divididas por <b>Ano</b> e <b>Mês</b> e organizadas por categorias.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. É possível navegar entre os anos e meses utilizando os botões <v-btn small color="#009263" class="white--text ml-2" rounded><v-icon>mdi-chevron-left</v-icon></v-btn> e<v-btn small color="#009263" class="white--text ml-2" rounded><v-icon>mdi-chevron-right</v-icon></v-btn> respetivos.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Existem atualmente 4 categorias dedicadas para os vários anos do Ensino Básico e cada uma delas é representada por um botão na secção abaixo: 
                    <br/>
                    <span class="ml-4">
                      3.1. <b>Nível 1</b> - 1º e 2º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      3.2. <b>Nível 2</b> - 3º e 4º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      3.3. <b>Nível 3</b> - 5º e 6º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      3.4. <b>Nível 4</b> - 7º, 8º e 9º anos
                      <br/>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. Cada categoria pode ter uma número variável de questões, desde 0 até um número arbitrário.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. Ao clicar numa categoria, irá abrir um navegador com o conjunto de questões e as respetivas soluções que foram selecionadas para esse ano, mês e categoria.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    6. Em cada uma das questões é possível visualizar se obteve uma resposta correta ou errada consoante a resolução que submeteu.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    7. As soluções do mês atual só estarão disponíveis no <b>dia 1 do próximo mês</b>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    8. Não é possível alterar nem remover uma resolução já submetida.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
            <div v-if="loading">
                <center>
                <v-img
                    :src="require('@/assets/loading.gif')"
                    width="150px"
                    heigth="150px"
                >
                </v-img>
                </center>
            </div>
            <div v-else>
            <!-- Date picker -->
<!--             <v-row align="center" justify="center">
                <v-col
                  cols="12" xs="12" sm="6" md="6" lg="6" xl="6"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    @input="changedDate"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Selecione a data"
                        prepend-icon="mdi-calendar"
                        color="#009263"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      class="removeBorder"
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                      color="#009263"
                      locale="pt"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="#009263"
                        @click="menu = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="#009263"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
            </v-row> -->
              <div v-if="anos.length==0">
                <center>
                  <h3>De momento não existe um histórico disponível.</h3>
                </center>
              </div>
              <div v-else>
                  <v-row align="center" justify="center">
                    <v-col align="center">
                      <v-btn
                        color="#009263"
                        class="white--text"
                        large
                        rounded
                        @click="backOne"
                        :disabled="prevDisabled"
                      >
                        <v-icon>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                      <span style="color:#009263; font-weight: bold; font-size: larger" class="ml-2 mr-2">{{getCurrentAno}}</span>
                      <v-btn
                        color="#009263"
                        class="white--text mr-2"
                        large
                        rounded
                        @click="forwardOne"
                        :disabled="nextDisabled"
                        >
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                      <v-btn
                        color="#009263"
                        class="white--text ml-2"
                        large
                        rounded
                        @click="backOneMes"
                        :disabled="prevDisabledMes"
                      >
                        <v-icon>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                      <span style="color:#009263; font-weight: bold; font-size: larger" class="ml-2 mr-2">{{getCurrentMes}}</span>
                      <v-btn
                        color="#009263"
                        class="white--text"
                        large
                        rounded
                        @click="forwardOneMes"
                        :disabled="nextDisabledMes"
                        >
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                    
                  </v-row>
                  <center><h2 style="color:#009263" class="mt-4 mb-4">Categorias</h2></center>
                  <v-btn-toggle
                    v-model="categoria"
                    mandatory
                    @change="changeCategoria"
                    class="d-flex align-content-start flex-wrap"
                    color="#009263"
                  >
                    <v-btn
                          large
                          class="px-2 flex-grow-1 rounded-lg ml-auto mr-auto" 
                          v-for="(item,idx) in categorias"
                          :key="idx"
                          >
                          {{item}}
                    </v-btn>
                  </v-btn-toggle>
                  <center><h2 style="color:#009263" class="mt-4 mb-4">Questões Selecionadas</h2></center>
                  <!-- Question Scroller -->  
                  <v-row v-if="hasQuestions">
                    <v-col class=" mt-auto mb-auto"
                        align="right"
                        cols="1" sm="1" md="1" lg="1" xl="1">
                        <v-tooltip v-if="isFirstPage" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            disabled
                              large
                              fab
                              color="#009263"
                              class="white--text"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon dark x-large>
                                mdi-chevron-left
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Página Anterior</span>
                        </v-tooltip>
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              large
                              fab
                              color="#009263"
                              class="white--text"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="decrementPages"
                            >
                              <v-icon dark x-large>
                                mdi-chevron-left
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Página Anterior</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="10" sm="12" xs="12" md="10" lg="10" xl="10">
                      <!-- {{getCurrentResolution}} -->
                      <!-- <h4 class="ml-8">[{{getCurrentPergunta.cod}}]</h4> -->
                      <Preview :hasResol="getCurrentResolution" :pergunta="getCurrentPergunta"/>
                    </v-col>
                    
                    <v-col class="mt-auto mb-auto hidden-sm-and-down"
                      align="left"
                        cols="1" sm="1" md="1" lg="1" xl="1">
                        <v-tooltip v-if="isLastPage" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              large
                              fab
                              color="#009263"
                              class="white--text"
                              v-bind="attrs"
                              v-on="on"
                              disabled
                            >
                              <v-icon dark x-large>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Página Seguinte</span>
                        </v-tooltip>
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              large
                              fab
                              color="#009263"
                              class="white--text"
                              v-bind="attrs"
                              v-on="on"
                              @click="incrementPages"
                            >
                              <v-icon dark x-large>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Página Seguinte</span>
                        </v-tooltip>
                    </v-col>
                  </v-row>
                  <center v-else>
                  <h3>Não foram adicionadas questões nesta categoria no mês e ano selecionados.</h3>
                </center>
              </div>
            </div>
          </div>
          <div v-else>
            <NoAuth @login="$emit('login')"/>
          </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
//const currHostImages = require("@/config/hosts").currHostImages;
//const hostImages = require("@/config/hosts").hostImagesSelector[currHostImages];
//const Swal = require("sweetalert2");
import Preview from "../components/Preview.vue";
import NoAuth from "@/components/NoAuth.vue";


export default {
  components:{
    Preview, NoAuth
  },
  props: {

  },
  data() {
    return {
      loading: true,
      show:false,

      date: '',//new Date().toISOString().substr(0, 7),
      menu: false,

      history: {},
      anos: [],
      currentAno: 0,

      mesesString: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
      meses: [],
      currentMes: 0,
      
      categorias: ["Nível 1", "Nível 2", "Nível 3", "Nível 4"], // 0, 1, 2, 3
      categoria: 0,

      currentPergunta: 0,

      resolucoes: {}
    };
  },
  methods: {
    async getHistory(){
      const data = await axios.get(host + 'questoes_mensais/disponiveis')
      this.history = data.data
      this.anos = Object.keys(this.history).reverse()
      if(this.anos.length > 0){
        this.meses = Object.keys(this.history[this.anos[this.currentAno]]).reverse()
      }
      this.loading = false
    },

    async getResolutionHistory(){
      const data = await axios.get(host + 'questoes_mensais_resolucoes/todas')
      this.resolucoes = data.data
    },
    
    
    forwardOne(){
      this.currentMes = 0
      this.categoria = 0
      this.currentPergunta = 0
      this.currentAno = Math.max(0 , this.currentAno - 1)
      this.meses = Object.keys(this.history[this.anos[this.currentAno]]).reverse()
    },

    backOne(){
      this.currentMes = 0
      this.currentPergunta = 0
      this.categoria = 0
      this.currentAno = Math.min(this.currentAno + 1, this.anos.length - 1)
      this.meses = Object.keys(this.history[this.anos[this.currentAno]]).reverse()
    },

    forwardOneMes(){
      this.categoria = 0
      this.currentPergunta = 0
      this.currentMes = Math.max(0 , this.currentMes - 1)
    },

    backOneMes(){
      this.categoria = 0
      this.currentPergunta = 0
      this.currentMes = Math.min(this.currentMes + 1, this.meses.length - 1)
    },

    changeCategoria(){
      this.currentPergunta = 0
    },

    incrementPages(){
      this.currentPergunta = Math.min(this.currentPergunta + 1, this.history[this.anos[this.currentAno]][this.meses[this.currentMes]][this.categoria].length - 1)
    },

    decrementPages(){
      this.currentPergunta = Math.max(this.currentPergunta - 1, 0)
    }

  },
  computed: {
    getCurrentAno(){
      return this.anos[this.currentAno]
    },

    getCurrentMes(){
      return this.mesesString[this.meses[this.currentMes] - 1]
    },

    prevDisabled(){
      return this.currentAno == this.anos.length - 1
    },

    nextDisabled(){
      return this.currentAno == 0
    },

    prevDisabledMes(){
      return this.currentMes == this.meses.length - 1
    },

    nextDisabledMes(){
      return this.currentMes == 0
    },

    hasQuestions(){
      let arr = this.history[this.anos[this.currentAno]][this.meses[this.currentMes]][this.categoria]
      
      return arr && arr.length > 0
    },

    getCurrentPergunta(){
      return this.history[this.anos[this.currentAno]][this.meses[this.currentMes]][this.categoria][this.currentPergunta]
    },

    isFirstPage(){
      return this.currentPergunta == 0
    },

    isLastPage(){
      return this.currentPergunta == this.history[this.anos[this.currentAno]][this.meses[this.currentMes]][this.categoria].length - 1
    },

    getCurrentResolution(){
      let currp = this.history[this.anos[this.currentAno]][this.meses[this.currentMes]][this.categoria][this.currentPergunta]
      let idx = this.resolucoes[this.anos[this.currentAno]]?.[this.meses[this.currentMes]]?.[this.categoria]?.map(item => item.codquestao)?.indexOf(currp.cod)
      let obj = {
          hasResol: idx !== -1 && idx !== undefined ,
          resol: idx !== -1 && idx !== undefined ? this.resolucoes[this.anos[this.currentAno]][this.meses[this.currentMes]][this.categoria][idx].resol : null
      }
      return obj 

      /* return {
        ano: this.currentAno,
        mes: this.currentMes,
        categoria: this.categoria,
        pergunta: this.currentPergunta
      } */
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  mounted() {
    if(this.$store.getters.isAuthenticated){
      this.getResolutionHistory()
      this.getHistory()
    }
  }
};
</script>

<style>
.v-date-picker-table > table > tbody > tr > td{
  border-left: 0px;
  /* background-color: red; */
}
</style>
