<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center">
            <h2 style="color:#009263">Quero Resolver Questões de...</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página contém todos os temas de questões disponíveis atualmente.
                  </span>
                </v-col>

                <v-col cols="12">
                  <span>
                    2. Os temas estão divididos em várias páginas e é possível navegar entre elas utilizando os botões <v-btn small fab color="#009263" class="white--text" dark><v-icon dark> mdi-chevron-left </v-icon></v-btn> e <v-btn small fab color="#009263" class="white--text" dark><v-icon dark> mdi-chevron-right </v-icon></v-btn>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Ao clicar numa das imagens que define o tema, será reencaminhado para a página que contém todas as questões relativas ao mesmo.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
        <div v-if="!loading">
        <v-row >
            <v-col class=" mt-auto mb-auto hidden-sm-and-down"
              align="right"
                cols="1" sm="1" md="1" lg="1" xl="1">
                <v-tooltip v-if="isFirstPage" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    disabled
                      large
                      fab
                      color="#009263"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark x-large>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Página Anterior</span>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      large
                      fab
                      color="#009263"
                      class="white--text"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="decrementPages"
                    >
                      <v-icon dark x-large>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Página Anterior</span>
                </v-tooltip>
            </v-col>

            <v-col cols="10" sm="12" xs="12" md="10" lg="10" xl="10">
              <v-row>
              <v-col 
                v-for="item in chunkTemas"
                :key="item.codtema"
                class="d-flex child-flex"
                cols="12" sm="12" md="6" lg="4" xl="3"
              >
                  <v-btn
                   @click="pickedTema(item.codtema)"
                   color="#009263" width="280" height="280" max-width="280" min-width="280" class="rounded-lg ml-auto mr-auto mt-4" 
                   style=" border-radius: 35px!important;"
                   >
                    <v-img
                        :src="imageLink(item.img)"
                        :lazy-src="imageLink(item.img)"
                        aspect-ratio="1"
                        class="ml-auto mr-auto rounded-lg "
                        width="256" height="256" max-width="256" min-width="256"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                  </v-btn>
              </v-col>
              <v-col 
                v-for="item in chunkSize - chunkTemas.length"
                :key="item*-1"
                class="d-flex child-flex"
                cols="12" sm="12" md="6" lg="4" xl="3"
              >
                  <v-btn v-if="isLastPage"
                  disabled
                  color="white" width="280" height="280" max-width="280" min-width="280" class="rounded-lg ml-auto mr-auto mt-4" 
                  style="background-color:white!important">
                  </v-btn>
              </v-col>

              </v-row>
            </v-col>
            
            <v-col class="mt-auto mb-auto hidden-sm-and-down"
              align="left"
                cols="1" sm="1" md="1" lg="1" xl="1">
                  <v-tooltip v-if="isLastPage" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      large
                      fab
                      color="#009263"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                      disabled
                    >
                      <v-icon dark x-large>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Página Seguinte</span>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      large
                      fab
                      color="#009263"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                      @click="incrementPages"
                    >
                      <v-icon dark x-large>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Página Seguinte</span>
                </v-tooltip>
            </v-col>
          </v-row>
          <!-- FOR SMALL SCREENS -->
          <v-row class="hidden-md-and-up">
            <v-col class="mt-auto mb-auto"
              align="right"
                cols="6" sm="6" md="6" lg="6" xl="6">
                <v-tooltip v-if="isFirstPage" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      disabled
                      large
                      fab
                      color="#009263"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark x-large>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Página Anterior</span>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      large
                      fab
                      color="#009263"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                      @click="decrementPages"
                    >
                      <v-icon dark x-large>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Página Anterior</span>
                </v-tooltip>
            </v-col>
            <v-col class="mt-auto mb-auto"
              align="left"
                cols="6" sm="6" md="6" lg="6" xl="6">
                <v-tooltip v-if="isLastPage" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      disabled
                      large
                      fab
                      color="#009263"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark x-large>
                       mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Página Seguinte</span>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      large
                      fab
                      color="#009263"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                      @click="incrementPages"
                    >
                      <v-icon dark x-large>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Página Seguinte</span>
                </v-tooltip>
            </v-col>
          </v-row>
        </div>
        <v-container v-else>
            <center>
              <v-img
                :src="require('@/assets/loading.gif')"
                width="150px"
                heigth="150px"
              >
              </v-img>
            </center>
        </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const currHostImages = require("@/config/hosts").currHostImages;
const hostImages = require("@/config/hosts").hostImagesSelector[currHostImages];

export default {
  components: {
  },
  data() {
    return {
      loading: true,
      show: false,
      temas: [],
      chunkTemas: [],
      chunkSize: 12,
      currentPage: 1,
      pages: 1
    };
  },
  props: {
    page_before: {
      type: Number,
      default: 1
    }
  },
  methods: {
    async getTemas(){
      const data = await axios.get(host + 'temas/agruparImagens')
      let ans = []
      let acc = []
      this.temas = data.data
      for(let tema of this.temas){
        if(tema.ordem === null){
          acc.push(tema)
        }
        else {
          ans[tema.ordem] = tema
        }
      }
      this.temas = ans.concat(acc)
      this.temas = this.temas.filter(item => item.codtema != 200 && item.codtema != 40)
      this.currentPage = this.page_before
      this.updateChunkTemas()
      this.loading = false
    },
    updateChunkTemas() {
      let arr = this.temas
      let to = this.currentPage * this.chunkSize;
      let from = to - this.chunkSize;
      this.pages = Math.ceil(arr.length / this.chunkSize);
      this.chunkTemas = arr.slice(from, to)
    },

    decrementPages(){
      this.currentPage = Math.max(this.currentPage - 1, 1)
      this.updateChunkTemas()
    },

    incrementPages(){
      this.currentPage = Math.min(this.currentPage + 1, this.pages)
      this.updateChunkTemas()
    },

    imageLink(url){
      let path = url==='' || url===null ? "imagemPI.png" : url
      return hostImages + path
    },

    pickedTema(codtema){
      let lastPage = this.currentPage
      this.$router.push({
        name: "SubtemaSelector",
        params: {lastPage, codtema}
      })
    }
  },
  computed: {
    isFirstPage(){
      return this.currentPage === 1
    },
    isLastPage(){
      return this.currentPage === this.pages
    }
  },

  watch: {
      "$vuetify.breakpoint.name": {
          handler(value) {
            switch(value){
              case 'xs': 
                this.chunkSize = 4
                break
              case 'sm': 
                this.chunkSize = 4
                break
              case 'md':
                this.chunkSize = 4
                break
              case 'lg': 
                this.chunkSize = 9
                break
              case 'xl': 
                this.chunkSize = 12
                break
            }
            this.currentPage = 1
            this.updateChunkTemas()
          },
          deep: true,
          immediate: true,
      },
  },

  mounted() {
    this.getTemas()
  },
};
</script>
<style scoped>
</style>