<template>
  <div>
    
    <v-row>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <h2 style="color:#009263" class="ml-8 text-left my-2">
          <span>Questão</span>
        </h2>
        <h4 style="color:#009263" class="ml-8 text-left my-2">
          <span>[{{pergunta.cod}}]</span>
        </h4>
        <h4 style="color:#009263" class="ml-8 text-left my-2">
          <span>Nível {{pergunta.niveldificuldade}}</span>
        </h4>
        
        <div
          v-html="pergunta.questao"
          class="mt-5 ml-8 mr-2"
        >
        </div>
        <div class="mt-5 ml-8">
          <!-- Resposta Aberta -->
          <div v-if="pergunta.tipo === 1" class="mt-8 mr-2">
              <v-row>
                <v-col cols=11>
                  <v-text-field
                    color="#009263"
                    outlined
                    hide-details
                    disabled
                    readonly
                  >
                    <template slot="append"> <span v-html="pergunta.unidade"></span> </template>
                    <template slot="label">
                      <span
                        class="black--text"
                        v-html="hasResol.hasResol ? hasResol.resol : ''"
                      ></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col v-if="hasResol.hasResol" cols=1 class="  mt-auto mb-auto">
                  <v-icon large dense color="#009263" v-if="verify">mdi-check-circle</v-icon>
                  <v-icon large dense color="red" v-else>mdi-close-circle</v-icon>
                </v-col>
              </v-row>

              <h2 style="color:#009263" class="mt-5 mb-5 mr-2">
                <span v-if="pergunta.auxiliar===1">Resposta Aceite</span>
                <span v-else-if="pergunta.auxiliar===2">Resposta Aceite</span>
                <span v-else-if="pergunta.auxiliar===10">Respostas Aceites</span>
                <span v-else-if="pergunta.auxiliar===22">Respostas Aceites</span>
                <span v-else>Respostas Aceites</span>
              </h2>
              <div v-if="pergunta.auxiliar===1">
                 <ul>
                  <li
                    v-for="(resp, index) in getRespostasAberta"
                    :key="index"
                  >
                    <span v-html="resp"></span>
                  </li>
                </ul>
              </div>
              <div v-else-if="pergunta.auxiliar===2">
                <ul>
                  <li>
                    Fração Irredutível: 
                     <b>{{pergunta.resposta1 + '/' + pergunta.resposta2}}</b>
                  </li>
                </ul>
              </div>
              <div v-else-if="pergunta.auxiliar===10">
                <ul>
                  <li>
                    Qualquer valor entre: 
                     <b>{{pergunta.resposta1}}º</b>
                     e
                     <b>{{pergunta.resposta2}}º</b>
                  </li>
                </ul>
              </div>
              <div v-else-if="pergunta.auxiliar===22">
                <ul>
                  <li>
                    Qualquer fração equivalente a: 
                     <b>{{pergunta.resposta1 + '/' + pergunta.resposta2}}</b>
                  </li>
                </ul>
              </div>
              <div v-else>
                 <ul style="list-style-type: none;">
                  <li
                    v-for="(resp, index) in getRespostasAberta"
                    :key="index"
                  >
                    <b> {{ index + 1 }}) </b>
                    <span v-html="resp"></span>
                  </li>
                </ul>
              </div>
          </div>
          <!-- VER COMMENTS ABAIXO, COMPLETAR -->

         <!-- Escolha Múltipla TIPO 2 -->
        <v-container v-else-if="pergunta.tipo === 2">
          <v-row>
            <v-col
              v-for="(resp, index) in getRespostas"
              :key="index"
              cols="6"
              sm="3"
              md="3"
              lg="3"
              xl="3"
            >
               <b v-if="hasResol.hasResol && hasResol.resol === resp" ><v-icon>mdi-chevron-double-right</v-icon> {{ index + 1 }}) <v-icon>mdi-chevron-double-left</v-icon></b>
               <b v-else> {{ index + 1 }})</b>
                <span v-if="index===0">
                  <v-icon dense color="#009263">mdi-check-circle</v-icon>
                </span>
                <span v-else-if="hasResol.hasResol && hasResol.resol === resp">
                  <v-icon dense color="red">mdi-close-circle</v-icon>
                </span>
              <v-img
                v-if="image_urls"
                contain
                :src="image_urls[index + 1]"
              ></v-img>
              <v-img
                v-else
                contain
                :src="grabRespostaURL(pergunta['resposta' + (index + 1)])"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
         <!-- Escolha Multipla TIPO 0, 1, 3, 4, 5, 6 / VF -->
          <v-container v-else-if="pergunta.tipo < 7">
            <ul style="list-style-type: none;">
              <li
                v-for="(resp, index) in getRespostas"
                :key="index"
              >
                <b v-if="hasResol.hasResol && hasResol.resol === resp" ><v-icon>mdi-chevron-double-right</v-icon> {{ index + 1 }}) <v-icon>mdi-chevron-double-left</v-icon></b>
               <b v-else> {{ index + 1 }})</b>
                <span v-if="index===0">
                  <span v-html="resp"></span>
                  <v-icon dense color="#009263">mdi-check-circle</v-icon>
                </span>
                <span v-else-if="hasResol.hasResol && hasResol.resol === resp">
                  <span v-html="resp"></span>
                  <v-icon dense color="red">mdi-close-circle</v-icon>
                </span>
                <span v-else v-html="resp"></span>
              </li>
            </ul>
          </v-container>

          <!-- Pintar -->
          <v-container v-else-if="pergunta.tipo === 7">
                 <!-- Vertical Grid -->
            <div
              v-if="tipoGrid === 'v'"
            >
              <v-row>
                <v-col cols="6">
                  <div style="margin-top:10px;">
                    <grid-layout
                      :layout.sync="grid.layoutEsq"
                      :col-num="21"
                      :row-height="18"
                      :is-draggable="grid.draggable"
                      :is-resizable="grid.resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        :style="corGridEsq(item.i, 0)"
                        v-for="item in grid.layoutEsq"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                      >
                      </grid-item>
                    </grid-layout>
                  </div>
                </v-col>
                <v-divider
                  style="border: 5px solid black;"
                  class="mx-n6 background-color: black"
                  vertical
                ></v-divider>
                <v-col cols="6">
                  <div style="margin-top:10px;">
                    <grid-layout
                      :layout.sync="grid.layoutDir"
                      :col-num="21"
                      :row-height="18"
                      :is-draggable="grid.draggable"
                      :is-resizable="grid.resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        :style="corGridDir(item.i,0)"
                        v-for="item in grid.layoutDir"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                      >
                      </grid-item>
                    </grid-layout>
                  </div>
                </v-col>
              </v-row>
            </div>

            <!-- Horizontal Grid -->
            <div
              v-else
            >
              <v-row no-gutters justify="center" align="center">
                <v-col cols="8">
                  <div class="my-n3">
                    <grid-layout
                      :layout.sync="grid.layoutTop"
                      :col-num="26"
                      :row-height="18"
                      :is-draggable="grid.draggable"
                      :is-resizable="grid.resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        :style="corGridTop(item.i,0)"
                        v-for="item in grid.layoutTop"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                      >
                      </grid-item>
                    </grid-layout>
                  </div>
                </v-col>

                <v-col cols="8">
                  <v-divider
                    style="border: 5px solid black;"
                    class="background-color: black"
                  ></v-divider>
                </v-col>
                <v-col cols="8">
                  <div class="my-n3">
                    <grid-layout
                      :layout.sync="grid.layoutBtm"
                      :col-num="26"
                      :row-height="18"
                      :is-draggable="grid.draggable"
                      :is-resizable="grid.resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        :style="corGridBtm(item.i,0)"
                        v-for="item in grid.layoutBtm"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                      >
                      </grid-item>
                    </grid-layout>
                  </div>
                </v-col>
              </v-row>
            </div> 
            <div v-if="hasResol.hasResol">
            <h2 style="color:#009263" class="mt-5 mb-5 mr-2">
              Resposta Submetida
            </h2>
            <div
              v-if="tipoGrid === 'v'"
            >
              <v-row>
                <v-col cols="6">
                  <div style="margin-top:10px;">
                    <grid-layout
                      :layout.sync="grid.layoutEsq"
                      :col-num="21"
                      :row-height="18"
                      :is-draggable="grid.draggable"
                      :is-resizable="grid.resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        :style="corGridEsq(item.i,1)"
                        v-for="item in grid.layoutEsq"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                      >
                      </grid-item>
                    </grid-layout>
                  </div>
                </v-col>
                <v-divider
                  style="border: 5px solid black;"
                  class="mx-n6 background-color: black"
                  vertical
                ></v-divider>
                <v-col cols="6">
                  <div style="margin-top:10px;">
                    <grid-layout
                      :layout.sync="grid.layoutDir"
                      :col-num="21"
                      :row-height="18"
                      :is-draggable="grid.draggable"
                      :is-resizable="grid.resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        :style="corGridDir(item.i,1)"
                        v-for="item in grid.layoutDir"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                      >
                      </grid-item>
                    </grid-layout>
                  </div>
                </v-col>
              </v-row>
            </div>

            <!-- Horizontal Grid -->
            <div
              v-else
            >
              <v-row no-gutters justify="center" align="center">
                <v-col cols="8">
                  <div class="my-n3">
                    <grid-layout
                      :layout.sync="grid.layoutTop"
                      :col-num="26"
                      :row-height="18"
                      :is-draggable="grid.draggable"
                      :is-resizable="grid.resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        :style="corGridTop(item.i,1)"
                        v-for="item in grid.layoutTop"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                      >
                      </grid-item>
                    </grid-layout>
                  </div>
                </v-col>

                <v-col cols="8">
                  <v-divider
                    style="border: 5px solid black;"
                    class="background-color: black"
                  ></v-divider>
                </v-col>
                <v-col cols="8">
                  <div class="my-n3">
                    <grid-layout
                      :layout.sync="grid.layoutBtm"
                      :col-num="26"
                      :row-height="18"
                      :is-draggable="grid.draggable"
                      :is-resizable="grid.resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        :style="corGridBtm(item.i,1)"
                        v-for="item in grid.layoutBtm"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                      >
                      </grid-item>
                    </grid-layout>
                  </div>
                </v-col>
              </v-row>
            </div> 
            </div>
          </v-container>

          <!-- Qualquer outra -->
          <v-container v-else>
            <span class="mt-5 ml-8 mr-2">
              <v-icon dense color="#009263">mdi-exclamation-thick</v-icon> Ocorreu um erro na pré-visualização: tipo de pergunta não suportado.
            </span>
          </v-container>
        </div>
        <div align="center" class=" pa-2 mt-5 mb-5">
          <v-dialog 
            v-model="dialog"
            :max-width="resolWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="white--text"
                color="#009263"
                v-bind="attrs"
                v-on="on"
                :disabled="resolDisabled"
              >
                Resolução
              </v-btn>
            </template>
            <v-card>
              <v-img v-if="image_urls" contain :src="image_urls.resol"></v-img>
              <v-img v-else contain :src="resolucao"></v-img>
              <v-card-actions class="text-center">
                <v-btn
                  :style="{
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }"
                  color="#009263"
                  text
                  @click="dialog = false"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
      <v-col class="mt-n2" cols="12" sm="4" md="4" lg="4" xl="4">
        <v-container>
          <v-row>
            <v-col class="mt-n4 mb-4" cols="12" sm="12" lg="6">
              <div>
                <v-btn
                  v-if="pergunta.tipo===1 && pergunta.auxiliar===10"
                  @click="transferidor = !transferidor"
                  dark
                  small
                  rounded
                  color="#009263"
                  >Transferidor</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card class="mt-n1 ">
          <v-img v-if="image_urls" min-height="400px" max-height="400px" contain :src="image_urls['main']"></v-img>
          <v-img v-else-if="pergunta.figura" min-height="400px" max-height="400px" contain :src="imagem"></v-img>
          <Moveable
            v-if="transferidor"
            v-bind="moveable"
            @drag="handleDrag"
            @rotate="handleRotate"
          >
            <v-img src="@/assets/transferidor.png"></v-img>
          </Moveable>
        </v-card>
        <v-container>
          
          <div class="mt-3 mb-3" v-if="pergunta.idexame !== ''" id="exame">
            <span> <b> Exame: </b> {{ pergunta.idexame }}</span>
          </div>
          <div v-else class="mt-3 mb-3">

          </div>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
//const axios = require("axios");
//const host = require("@/config/hosts").hostAPI;
import { GridLayout, GridItem } from "vue-grid-layout";
const hostImages = require("@/config/hosts").hostImages;
const hostImagesResol = require("@/config/hosts").hostImagesResol;
const currHostImages = require("@/config/hosts").currHostImages;
import Moveable from "vue-moveable";

export default {
  components: {
    GridLayout,
    GridItem,
    Moveable
  },
  data() {
    
    return {
      transferidor: false,
      dialog: false,
      moveable: {
        draggable: true,
        throttleDrag: 1,
        resizable: false,
        throttleResize: 1,
        keepRatio: true,
        scalable: false,
        throttleScale: 0.01,
        rotatable: true,
        throttleRotate: 0.2,
        pinchable: false,
        origin: false,
      },
      resolCard: "900px",
       grid: {
        layoutTop: [
          { x: 0, y: 0, w: 5, h: 2, i: "5", static: true },
          { x: 4, y: 0, w: 5, h: 2, i: "10", static: true },
          { x: 8, y: 0, w: 5, h: 2, i: "15", static: true },
          { x: 12, y: 0, w: 5, h: 2, i: "20", static: true },
          { x: 16, y: 0, w: 5, h: 2, i: "25", static: true },
          { x: 20, y: 0, w: 5, h: 2, i: "30", static: true },

          { x: 0, y: 1.4, w: 5, h: 2, i: "4", static: true },
          { x: 4, y: 1.4, w: 5, h: 2, i: "9", static: true },
          { x: 8, y: 1.4, w: 5, h: 2, i: "14", static: true },
          { x: 12, y: 1.4, w: 5, h: 2, i: "19", static: true },
          { x: 16, y: 1.4, w: 5, h: 2, i: "24", static: true },
          { x: 20, y: 1.4, w: 5, h: 2, i: "29", static: true },

          { x: 0, y: 2.8, w: 5, h: 2, i: "3", static: true },
          { x: 4, y: 2.8, w: 5, h: 2, i: "8", static: true },
          { x: 8, y: 2.8, w: 5, h: 2, i: "13", static: true },
          { x: 12, y: 2.8, w: 5, h: 2, i: "18", static: true },
          { x: 16, y: 2.8, w: 5, h: 2, i: "23", static: true },
          { x: 20, y: 2.8, w: 5, h: 2, i: "28", static: true },

          { x: 0, y: 4.2, w: 5, h: 2, i: "2", static: true },
          { x: 4, y: 4.2, w: 5, h: 2, i: "7", static: true },
          { x: 8, y: 4.2, w: 5, h: 2, i: "12", static: true },
          { x: 12, y: 4.2, w: 5, h: 2, i: "17", static: true },
          { x: 16, y: 4.2, w: 5, h: 2, i: "22", static: true },
          { x: 20, y: 4.2, w: 5, h: 2, i: "27", static: true },

          { x: 0, y: 5.6, w: 5, h: 2, i: "1", static: true },
          { x: 4, y: 5.6, w: 5, h: 2, i: "6", static: true },
          { x: 8, y: 5.6, w: 5, h: 2, i: "11", static: true },
          { x: 12, y: 5.6, w: 5, h: 2, i: "16", static: true },
          { x: 16, y: 5.6, w: 5, h: 2, i: "21", static: true },
          { x: 20, y: 5.6, w: 5, h: 2, i: "26", static: true },
        ],
        layoutBtm: [
          { x: 0, y: 0, w: 5, h: 2, i: "1", static: true },
          { x: 4, y: 0, w: 5, h: 2, i: "6", static: true },
          { x: 8, y: 0, w: 5, h: 2, i: "11", static: true },
          { x: 12, y: 0, w: 5, h: 2, i: "16", static: true },
          { x: 16, y: 0, w: 5, h: 2, i: "21", static: true },
          { x: 20, y: 0, w: 5, h: 2, i: "26", static: true },

          { x: 0, y: 1.4, w: 5, h: 2, i: "2", static: true },
          { x: 4, y: 1.4, w: 5, h: 2, i: "7", static: true },
          { x: 8, y: 1.4, w: 5, h: 2, i: "12", static: true },
          { x: 12, y: 1.4, w: 5, h: 2, i: "17", static: true },
          { x: 16, y: 1.4, w: 5, h: 2, i: "22", static: true },
          { x: 20, y: 1.4, w: 5, h: 2, i: "27", static: true },

          { x: 0, y: 2.8, w: 5, h: 2, i: "3", static: true },
          { x: 4, y: 2.8, w: 5, h: 2, i: "8", static: true },
          { x: 8, y: 2.8, w: 5, h: 2, i: "13", static: true },
          { x: 12, y: 2.8, w: 5, h: 2, i: "18", static: true },
          { x: 16, y: 2.8, w: 5, h: 2, i: "23", static: true },
          { x: 20, y: 2.8, w: 5, h: 2, i: "28", static: true },

          { x: 0, y: 4.2, w: 5, h: 2, i: "4", static: true },
          { x: 4, y: 4.2, w: 5, h: 2, i: "9", static: true },
          { x: 8, y: 4.2, w: 5, h: 2, i: "14", static: true },
          { x: 12, y: 4.2, w: 5, h: 2, i: "19", static: true },
          { x: 16, y: 4.2, w: 5, h: 2, i: "24", static: true },
          { x: 20, y: 4.2, w: 5, h: 2, i: "29", static: true },

          { x: 0, y: 5.6, w: 5, h: 2, i: "5", static: true },
          { x: 4, y: 5.6, w: 5, h: 2, i: "10", static: true },
          { x: 8, y: 5.6, w: 5, h: 2, i: "15", static: true },
          { x: 12, y: 5.6, w: 5, h: 2, i: "20", static: true },
          { x: 16, y: 5.6, w: 5, h: 2, i: "25", static: true },
          { x: 20, y: 5.6, w: 5, h: 2, i: "30", static: true },
        ],
        layoutEsq: [
          { x: 0, y: 0, w: 5, h: 2, i: "5", static: true },
          { x: 4, y: 0, w: 5, h: 2, i: "4", static: true },
          { x: 8, y: 0, w: 5, h: 2, i: "3", static: true },
          { x: 12, y: 0, w: 5, h: 2, i: "2", static: true },
          { x: 16, y: 0, w: 5, h: 2, i: "1", static: true },
          { x: 0, y: 1.6, w: 5, h: 2, i: "10", static: true },
          { x: 4, y: 1.6, w: 5, h: 2, i: "9", static: true },
          { x: 8, y: 1.6, w: 5, h: 2, i: "8", static: true },
          { x: 12, y: 1.6, w: 5, h: 2, i: "7", static: true },
          { x: 16, y: 1.6, w: 5, h: 2, i: "6", static: true },
          { x: 0, y: 3.2, w: 5, h: 2, i: "15", static: true },
          { x: 4, y: 3.2, w: 5, h: 2, i: "14", static: true },
          { x: 8, y: 3.2, w: 5, h: 2, i: "13", static: true },
          { x: 12, y: 3.2, w: 5, h: 2, i: "12", static: true },
          { x: 16, y: 3.2, w: 5, h: 2, i: "11", static: true },
          { x: 0, y: 4.8, w: 5, h: 2, i: "20", static: true },
          { x: 4, y: 4.8, w: 5, h: 2, i: "19", static: true },
          { x: 8, y: 4.8, w: 5, h: 2, i: "18", static: true },
          { x: 12, y: 4.8, w: 5, h: 2, i: "17", static: true },
          { x: 16, y: 4.8, w: 5, h: 2, i: "16", static: true },
          { x: 0, y: 6.4, w: 5, h: 2, i: "25", static: true },
          { x: 4, y: 6.4, w: 5, h: 2, i: "24", static: true },
          { x: 8, y: 6.4, w: 5, h: 2, i: "23", static: true },
          { x: 12, y: 6.4, w: 5, h: 2, i: "22", static: true },
          { x: 16, y: 6.4, w: 5, h: 2, i: "21", static: true },
          { x: 0, y: 8, w: 5, h: 2, i: "30", static: true },
          { x: 4, y: 8, w: 5, h: 2, i: "29", static: true },
          { x: 8, y: 8, w: 5, h: 2, i: "28", static: true },
          { x: 12, y: 8, w: 5, h: 2, i: "27", static: true },
          { x: 16, y: 8, w: 5, h: 2, i: "26", static: true },
        ],
        layoutDir: [
          { x: 0, y: 0, w: 5, h: 2, i: "1", static: true },
          { x: 4, y: 0, w: 5, h: 2, i: "2", static: true },
          { x: 8, y: 0, w: 5, h: 2, i: "3", static: true },
          { x: 12, y: 0, w: 5, h: 2, i: "4", static: true },
          { x: 16, y: 0, w: 5, h: 2, i: "5", static: true },
          { x: 0, y: 1.6, w: 5, h: 2, i: "6", static: true },
          { x: 4, y: 1.6, w: 5, h: 2, i: "7", static: true },
          { x: 8, y: 1.6, w: 5, h: 2, i: "8", static: true },
          { x: 12, y: 1.6, w: 5, h: 2, i: "9", static: true },
          { x: 16, y: 1.6, w: 5, h: 2, i: "10", static: true },
          { x: 0, y: 3.2, w: 5, h: 2, i: "11", static: true },
          { x: 4, y: 3.2, w: 5, h: 2, i: "12", static: true },
          { x: 8, y: 3.2, w: 5, h: 2, i: "13", static: true },
          { x: 12, y: 3.2, w: 5, h: 2, i: "14", static: true },
          { x: 16, y: 3.2, w: 5, h: 2, i: "15", static: true },
          { x: 0, y: 4.8, w: 5, h: 2, i: "16", static: true },
          { x: 4, y: 4.8, w: 5, h: 2, i: "17", static: true },
          { x: 8, y: 4.8, w: 5, h: 2, i: "18", static: true },
          { x: 12, y: 4.8, w: 5, h: 2, i: "19", static: true },
          { x: 16, y: 4.8, w: 5, h: 2, i: "20", static: true },
          { x: 0, y: 6.4, w: 5, h: 2, i: "21", static: true },
          { x: 4, y: 6.4, w: 5, h: 2, i: "22", static: true },
          { x: 8, y: 6.4, w: 5, h: 2, i: "23", static: true },
          { x: 12, y: 6.4, w: 5, h: 2, i: "24", static: true },
          { x: 16, y: 6.4, w: 5, h: 2, i: "25", static: true },
          { x: 0, y: 8, w: 5, h: 2, i: "26", static: true },
          { x: 4, y: 8, w: 5, h: 2, i: "27", static: true },
          { x: 8, y: 8, w: 5, h: 2, i: "28", static: true },
          { x: 12, y: 8, w: 5, h: 2, i: "29", static: true },
          { x: 16, y: 8, w: 5, h: 2, i: "30", static: true },
        ],
        draggable: false,
        resizable: false,
      }, 

    };
  },
  props: {
    pergunta: {
      type: Object,
      required: true
    },
    image_urls: {
      type: Object,
      required: false
    },

    hasResol:{
      type: Object,
      required: true
    }
  },
  methods: {

    // Grid Horizontal
    corGridTop(index, type) {
      let layout 
      layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      let lado = idxList[1];
      if(type == 1 && lado!=="c"){
        layout = this.hasResol.resol
        idxList = layout.split("-");
        lado = idxList[1];
      }

      idxList = idxList.splice(2);

      if (lado === "c")
        return idxList.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      else if ( type==1 && this.pergunta.resposta1 !== this.hasResol.resol){
        return idxList.includes(index)
          ? { background: "rgba(255, 30, 30, 1)" }
          : { background: "#eee" };
      }
      else {
          return idxList.includes(index)
          ? { background: "#4CC74D" } 
          : { background: "#eee" };
      }
    },

    corGridBtm(index, type) {
      let layout 
      layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      let lado = idxList[1];
      if(type == 1 && lado!=="b"){
        layout = this.hasResol.resol
        idxList = layout.split("-");
        lado = idxList[1];
      }

      idxList = idxList.splice(2);

      if (lado === "b")
        return idxList.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      else if ( type==1 && this.pergunta.resposta1 !== this.hasResol.resol){
        return idxList.includes(index)
          ? { background: "rgba(255, 30, 30, 1)" }
          : { background: "#eee" };
      }
      else {
          return idxList.includes(index)
          ? { background: "#4CC74D" } 
          : { background: "#eee" };
      }
    },
    // Grid Vertical
    corGridEsq(index, type) {
      let layout 
      layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      let lado = idxList[1];
      if(type == 1 && lado!=="e"){
        layout = this.hasResol.resol
        idxList = layout.split("-");
        lado = idxList[1];
      }

      idxList = idxList.splice(2);

      if (lado === "e")
        return idxList.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      else if ( type==1 && this.pergunta.resposta1 !== this.hasResol.resol){
        return idxList.includes(index)
          ? { background: "rgba(255, 30, 30, 1)" }
          : { background: "#eee" };
      }
      else {
          return idxList.includes(index)
          ? { background: "#4CC74D" } 
          : { background: "#eee" };
      }
    },
    corGridDir(index, type) {
      let layout 
      layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      let lado = idxList[1];
      if(type == 1 && lado!=="d"){
        layout = this.hasResol.resol
        idxList = layout.split("-");
        lado = idxList[1];
      }

      idxList = idxList.splice(2);

      if (lado === "d")
        return idxList.includes(index)
          ? { background: "#ff6600" }
          : { background: "#eee" };
      else if ( type==1 && this.pergunta.resposta1 !== this.hasResol.resol){
        return idxList.includes(index)
          ? { background: "rgba(255, 30, 30, 1)" }
          : { background: "#eee" };
      }
      else {
          return idxList.includes(index)
          ? { background: "#4CC74D" } 
          : { background: "#eee" };
      }
    },

    handleDrag({ target, transform }) {
      target.style.transform = transform;
    },
    handleRotate({ target, transform }) {
      target.style.transform = transform;
    },
    imgSize(url, callback) {
      var img = new Image();
      img.onload = function() {
        callback(img.width);
      };
      img.src = url;
    },
    grabRespostaURL(obj){
      let img = obj
      if(obj.match(/^bckqr\/uploads\/.+$/)){
        if(currHostImages === 'dev'){
          img = hostImages['dev'] + img.replace(/^bckqr\//, '') + '.png'
        }
        else {
          img = hostImages['prod'] + img + '.png'
        }
      }
      else {
        //let img = "addsub/img_add_sub_1"
        img = img
          ? `https://www.hypatiamat.com/imagens/${img.replace(".swf", "")}.png`
          : "";
        }
      return img;
    },

    reduce(numerator, denominator) {
        let a = numerator
        let b = denominator
        let c
        while (b) {
            c = a % b; a = b; b = c
        }
        return `${numerator / a}/${denominator / a}`
    },


  },
  computed: {
    tipoGrid() {
      const layout = this.pergunta.resposta1;
      let idxList = layout.split("-");
      const tipoGrid = idxList[0];
      // h ou v
      return tipoGrid;
    },

    getRespostas(){
      let i, respostas = []
      switch(this.pergunta.tipo){
        case 0:
          i = 4
          break 
        case 2:
          i = 4
          break
        case 3:
          i = 2
          break
        case 4:
          i = 3
          break 
        case 5:
          i = 5
          break 
        case 6:
          i = 6
          break
      }
      for(let j = 1; j <= i; j++){
        respostas.push(this.pergunta[`resposta${j}`])
      }
      return respostas
    },

    getRespostasAberta(){
      let i = 1, respostas = []
      switch(this.pergunta.auxiliar){
            case 0:
              while( this.pergunta[`resposta${i}`] !== '' ) i++
              i--
              break
            case 1:
              i = 1
              break 
            default:
              i = 2
              break
      }
      for(let j = 1; j <= i; j++){
        respostas.push(this.pergunta[`resposta${j}`])
      }
      return respostas
    }, 

    imagem() {
      let img = this.pergunta.figura;

      if(img.match(/^bckqr\/uploads\/.+$/)){
        if(currHostImages === 'dev'){
          img = hostImages['dev'] + img.replace(/^bckqr\//, '') + '.png'
        }
        else {
          img = hostImages['prod'] + img + '.png'
        }
      }
      else {
        img = img
          ? `https://www.hypatiamat.com/imagens/${img.replace(".swf", "")}.png`
          : "";
      }

      return img;
    },

    resolWidth() {
     let img

     if(this.image_urls){
       if(this.image_urls.resol){
         img = this.image_urls.resol
       }
       else {
         img = ""
       }
     }
     else {
        img = this.pergunta.resolucao;
         if(img.match(/^bckqr_r\/uploads\/.+$/)){
          if(currHostImages === 'dev'){
            img = hostImagesResol['dev'] + img.replace(/^bckqr_r\//, '') + '.png'
          }
          else {
            img = hostImagesResol['prod'] + img + '.png'
          }
        }
        else {
          img = img
            ? `https://www.hypatiamat.com/imagens/propresolucao/${img.replace(".swf","")}.png`
            : "";
        }
    }  

      this.imgSize(img, (w) => {
        this.resolCard = `${w}px`;
      });
      return this.resolCard;
    },

    resolucao() {
      let img = this.pergunta.resolucao;
       if(img.match(/^bckqr_r\/uploads\/.+$/)){
        if(currHostImages === 'dev'){
          img = hostImagesResol['dev'] + img.replace(/^bckqr_r\//, '') + '.png'
        }
        else {
          img = hostImagesResol['prod'] + img + '.png'
        }
      }
      else {
        img = img
          ? `https://www.hypatiamat.com/imagens/propresolucao/${img.replace(".swf","")}.png`
          : "";
      }
      
      return img;
    },

    resolDisabled(){
      return this.pergunta.resolucao === '' && this.image_urls?.resol == null // undefined = null
    },

    verify(){
      let correta = false
      let resol = this.hasResol.resol
      let resp, layout, elements, id, splitted
      switch(this.pergunta.tipo){
        case 1: // WIP
          switch (this.pergunta.auxiliar) {
            case 2:
              if(resol === `${this.pergunta.resposta1}/${this.pergunta.resposta2}`){
                correta = true
              }
              break;
            //transferidor
            case 10:
              if(resol >= this.pergunta.resposta1 && resol <= this.pergunta.resposta2){
                correta = true
              }
              break;
            case 22:
              splitted = resol.split("/")
              if(this.reduce(splitted[0], splitted[1]) === this.reduce(this.pergunta.resposta1, this.pergunta.resposta2)){
                correta = true
              }
              break;
            case 1000:
              if (this.pergunta.resposta1 === resol || this.pergunta.resposta2 === resol){
                correta = true
              }
              break;
            // auxiliar 0 e 1 (...)
            default:
              for (let i = 1; i < 7; i++) {
                if (this.pergunta[`resposta${i}`] !== "" && this.pergunta[`resposta${i}`] === resol) {
                  correta = true
                  break
                }
              }
              break
          }

          elements = document.querySelector(".v-text-field--outlined > .v-input__control > .v-input__slot")
          
          if(correta){
            //elements.querySelector("fieldset").style["border-color"] = "#4caf50"
            //elements.querySelector("fieldset").style["background-color"] = "#4caf50"
          }

          else{
            //elements.querySelector("fieldset").style["border-color"] = "rgba(255, 30, 30, 0.7)"
            //elements.querySelector("fieldset").style["background-color"] = "rgba(255, 30, 30, 0.7)"
          }

          break
        case 2:
          if(resol === this.pergunta.resposta1){
            correta = true
          }
          break;
        case 7:
          layout = this.pergunta.resposta1.split("-").splice(2)
          resp = resol.sort((a, b) => parseInt(a) - parseInt(b))
          // comparar arrays ordenados
          if(layout.toString() === resp.toString()){
            correta = true
          }
          resp = resol.join("-")
          break
        default: // 0 3 4 5 6
          if(this.pergunta.resposta1 === resol){
            correta = true
          }
          elements = document.querySelectorAll(".v-text-field--outlined > .v-input__control > .v-input__slot")
          elements.forEach(element => {
            id = element.querySelector('.v-text-field__slot > label').getAttribute("for")
            if(id === this.pergunta.resposta1){
              //element.querySelector("fieldset").style["border-color"] = "#4caf50"
              //element.querySelector("fieldset").style["background-color"] = "#4caf50"
            }
            else if (id === resol){
              //element.querySelector("fieldset").style["border-color"] = "rgba(255, 30, 30, 0.7)"
              //element.querySelector("fieldset").style["background-color"] = "rgba(255, 30, 30, 0.7)"
            }
          })
          break
      }
      return correta
    },
  },
  mounted() {
  },
};
</script>
<style scoped>

.vue-grid-item:not(.vue-grid-placeholder) {
  border: 1px solid black;
}

.selectExame {
  font-size: 14px;
  padding: 5px 6px;
  margin-top: -16px;
}
.selectNivel {
  color: #009263;
}

.btnItem {
  width: 100%;
  height: 100%;
  display: block;
}

/* se eu quiser meter a borda depois
.v-text-field--outlined >>> fieldset {
  border-color: rgb(0, 146, 99);
}
*/

</style>